import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

let PDFViewer = (props) => {
    const { pdf, setNumPages, pageNumber } = props

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
            {/* {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))} */}
            <Page pageNumber={pageNumber}/>    
        </Document>
    );
};

export default PDFViewer;