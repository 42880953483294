import * as imageActions from './imageActions';

export const imageFeatureKey = 'imageInfo';

let initialState = {
    loading: false,
    errMessage: "",
    petImageUrl: "",
    userImageUrl: "",
    documentUrl: "",
    productImageUrl: ""
};

export const reducer = (state = initialState, action) => {
    let { type, payload } = action;
    switch (type) {
        case imageActions.GET_PET_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            };

        case imageActions.GET_PET_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                petImageUrl: Object.values(payload.urls)[0]
            };

        case imageActions.GET_PET_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errMessage: payload,
                petImageUrl: ""
            };

        case imageActions.GET_USER_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            };

        case imageActions.GET_USER_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                userImageUrl: Object.values(payload.urls)[0]
            };

        case imageActions.GET_USER_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errMessage: payload,
                userImageUrl: ""
            };

        case imageActions.CLEAR_REDUX_STATE:
            return {
                ...state,
                userImageUrl: "",
                productImageUrl: ""
            };

        case imageActions.GET_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true
            };

        case imageActions.GET_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                documentUrl: Object.values(payload.urls)[0]
            };

        case imageActions.GET_DOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
                errMessage: payload,
                documentUrl: ""
            };

        case imageActions.GET_PRODUCT_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            };

        case imageActions.GET_PRODUCT_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                productImageUrl: Object.values(payload.urls)[0]
            };

        case imageActions.GET_PRODUCT_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errMessage: payload,
                productImageUrl: ""
            };

        default: return state;
    }
};