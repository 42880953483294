import axios from "axios";

export const GET_PET_REQUEST = 'GET_PET_REQUEST';
export const GET_PET_SUCCESS = 'GET_PET_SUCCESS';
export const GET_PET_FAILURE = 'GET_PET_FAILURE';

export const GET_PET_BY_ACCESSORY_REQUEST = 'GET_PET_BY_ACCESSORY_REQUEST';
export const GET_PET_BY_ACCESSORY_SUCCESS = 'GET_PET_BY_ACCESSORY_SUCCESS';
export const GET_PET_BY_ACCESSORY_FAILURE = 'GET_PET_BY_ACCESSORY_FAILURE';

// export const GET_PET_BY_QR_ID_REQUEST = 'GET_PET_BY_QR_ID_REQUEST';
// export const GET_PET_BY_QR_ID_SUCCESS = 'GET_PET_BY_QR_ID_SUCCESS';
// export const GET_PET_BY_QR_ID_FAILURE = 'GET_PET_BY_QR_ID_FAILURE';

export const GET_HUMAN_AGE_REQUEST = 'GET_HUMAN_AGE_REQUEST';
export const GET_HUMAN_AGE_SUCCESS = 'GET_HUMAN_AGE_SUCCESS';
export const GET_HUMAN_AGE_FAILURE = 'GET_HUMAN_AGE_FAILURE';

export const POST_PET_PARENT_REQUEST = 'POST_PET_PARENT_REQUEST';
export const POST_PET_PARENT_SUCCESS = 'POST_PET_PARENT_SUCCESS';
export const POST_PET_PARENT_FAILURE = 'POST_PET_PARENT_FAILURE';

export const PET_DETAILS_APP_REQUEST = 'PET_DETAILS_APP_REQUEST';
export const PET_DETAILS_APP_SUCCESS = 'PET_DETAILS_APP_SUCCESS';
export const PET_DETAILS_APP_FAILURE = 'PET_DETAILS_APP_FAILURE';

export const CLEAR_RES_STATUS = 'CLEAR_RES_STATUS';

export const getPetDetails = (petId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PET_REQUEST });
            let dataUrl = `${process.env.REACT_APP_GET_PET_DETAILS_URL}/dev/pet/details?pet_id=${petId}`;
            let response = await axios.get(dataUrl);
            dispatch({ type: GET_PET_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PET_FAILURE, payload: error });
            window.location.replace('https://jelliepets.com/invalidId');
        }
    }
};

export const getPetDetailsByAccessories = (accessoryId, lat, long, address) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PET_BY_ACCESSORY_REQUEST });
            // let dataUrl = `${process.env.REACT_APP_GET_PET_DETAILS_URL}/dev/pet/accessory?accessory_id=${accessoryId}`;
            let dataUrl = `${process.env.REACT_APP_GET_PET_DETAILS_URL}/dev/pet/accessory?accessory_id=${accessoryId}&latitude=${lat}&longitude=${long}&address=${address}`;
            let response = await axios.get(dataUrl);
            // if (!response) { window.location.replace('https://jelliepets.com/invalidurl') }
            dispatch({ type: GET_PET_BY_ACCESSORY_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PET_BY_ACCESSORY_FAILURE, payload: error });
            window.location.replace('https://jelliepets.com/invalidId');
        }
    }
};

// export const getPetDetailsByQrId = (accessoryId) => {
//     return async (dispatch) => {
//         try {
//             dispatch({ type: GET_PET_BY_QR_ID_REQUEST });
//             let dataUrl = `${process.env.REACT_APP_GET_PET_DETAILS_URL}/dev/pet/details_by_qr?qr_id=${accessoryId}`;
//             let response = await axios.get(dataUrl);
//             // if (!response) { window.location.replace('https://jelliepets.com/invalidurl') }
//             dispatch({ type: GET_PET_BY_QR_ID_SUCCESS, payload: response.data });
//         } catch (error) {
//             console.error(error);
//             dispatch({ type: GET_PET_BY_QR_ID_FAILURE, payload: error });
//             window.location.replace('https://jelliepets.com/invalidId');
//         }
//     }
// };

export const getHumanAge = (petAge, animalId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_HUMAN_AGE_REQUEST });
            let dataUrl = `${process.env.REACT_APP_GET_PET_DETAILS_URL}/dev/pet?pet_age=${petAge}&animal_id=${animalId}`;
            let response = await axios.get(dataUrl);
            dispatch({ type: GET_HUMAN_AGE_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_HUMAN_AGE_FAILURE, payload: error });
        }
    }
};

export const petParentRequest = (obj) => {
    return async (dispatch) => {
        dispatch({ type: CLEAR_RES_STATUS });
        try {
            dispatch({ type: POST_PET_PARENT_REQUEST });
            let dataUrl = `${process.env.REACT_APP_GET_PET_DETAILS_URL}/dev/pet/incoming_request`;
            let response = await axios.post(dataUrl, obj);
            dispatch({ type: POST_PET_PARENT_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: POST_PET_PARENT_FAILURE, payload: error });
        }
    }
};


export const petDetailsApp = (petId, userEmail) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PET_DETAILS_APP_REQUEST });
            dispatch({
                type: PET_DETAILS_APP_SUCCESS, payload: {
                    petId,
                    userEmail
                }
            });
        } catch (error) {
            console.error(error);
            dispatch({ type: PET_DETAILS_APP_FAILURE, payload: error });
        }
    }
};


export const clearResStatus = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: CLEAR_RES_STATUS });
        } catch (error) {
            console.error(error);
        }
    }
};