import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Col, Container, Row } from 'reactstrap';
import './petProfile.css';
import Collapsible from 'react-collapsible';
import qrcode from '../../assets/qrcode.png';
import {
    Link,
    useHistory,
    useLocation,
    useParams,
    matchPath,
} from 'react-router-dom';
import BackIcon from '../../assets/icons/Icon_256w_Back_Black.png';
import HeaderLogo from '../../assets/icons/Image_256w_JELLIEID_logo.png';
import * as petActions from '../../redux/pet/petActions';
import * as petReducers from '../../redux/pet/petReducers';
import { useDispatch, useSelector } from 'react-redux';
import * as imageActions from '../../redux/image/imageActions';
import * as imageReducers from '../../redux/image/imageReducers';
import NoPetImage from '../../assets/icons/Icon_256w_UnSelectedPet_NoImage.png';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import Geocode from "react-geocode";

let PetDetails = (props) => {
    const { match, history: historyProp } = props;
    const [petAge, setPetAge] = useState();
    const [petFilename, setPetFilename] = useState('');
    const history = useHistory();
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
    const dispatch = useDispatch();

    useEffect(() => {
        const id = sessionStorage.getItem('id');
        if (id) {
            dispatch(petActions.getPetDetails(id));
        } else {
            window.location.replace('https://jelliepets.com/invalidId');
        }
    }, []);

    // get pet info
    const petInfo = useSelector((state) => {
        return state[petReducers.petFeatureKey];
    });

    const { loading, pet, error, humanAge } = petInfo;
    if (error) console.error(error);

    useEffect(() => {
        if (pet) {
            if (pet?.birthdate) {
                setPetAge(
                    new Date().getFullYear() - new Date(pet?.birthdate).getFullYear()
                );
            }
        }
        if (pet === null) {
            window.location.replace('https://jelliepets.com/invalidId');
        }
    }, [pet]);

    // get human age
    useEffect(() => {
        if (pet) {
            if (petAge && pet?.animal_id) {
                dispatch(petActions?.getHumanAge(petAge, pet?.animal_id));
            }
        }
    }, [pet, petAge]);

    // get pet profile image
    useEffect(() => {
        if (pet?.pet_images?.length > 0) {
            const fileName = pet?.pet_images?.filter(
                (data) => data.type === 'PROFILE'
            );
            const exactFileName = fileName[fileName.length - 1]?.filename;
            setPetFilename(fileName[fileName.length - 1]?.filename);
            exactFileName && dispatch(imageActions.getPetImage(exactFileName));
        }
    }, [dispatch, pet]);

    let imageInfo = useSelector((state) => {
        return state[imageReducers.imageFeatureKey];
    });

    let { petImageUrl } = imageInfo;

    // clear User Image Redux State
    const clearUserImageState = () => {
        dispatch(imageActions.clearReduxState());
    };

    // location

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    function success(pos) {
        const crd = pos.coords;

        window
            .open(
                `https://www.google.com/maps/search/pet+shelter/@${crd.latitude},${crd.longitude}`,
                '_blank'
            )
            .focus();
    }

    function errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    const sendGpsLocation = () => {
        if (navigator.geolocation) {
            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                if (result.state === 'granted') {
                    navigator.geolocation.getCurrentPosition(success);
                } else if (result.state === 'prompt') {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                } else if (result.state === 'denied') {
                }
                result.onchange = function () {
                    // console.log(result.state);
                };
            });
        } else {
            alert('Sorry Not Available');
        }
    };

    const redirectUrl = (url) => {
        if (!url.startsWith('http') || !url.startsWith('https')) {
            window.open(`https://${url}`, '_blank');
        } else {
            window.open(`${url}`, '_blank');
        }
    };

    const saveStorage = (email) => {
        const encryptpetId = CryptoJS.AES.encrypt(
            JSON.stringify(pet?.id),
            process.env.REACT_APP_CRYPTO_SECRET
        ).toString();
        if (email) {
            const encryptEmail = CryptoJS.AES.encrypt(
                JSON.stringify(email),
                process.env.REACT_APP_CRYPTO_SECRET
            ).toString();
            dispatch(petActions.petDetailsApp(encryptpetId, encryptEmail));
        } else {
            dispatch(petActions.petDetailsApp(encryptpetId));
        }
    };

    return loading ? (
        <h2 className="mt-5 text-center text-muted">Loading...</h2>
    ) : (
        <React.Fragment>
            {pet && Object.keys(pet).length > 0 && (
                <React.Fragment>
                    <Fragment>
                        <Container fluid className="mt-3 mb-4">
                            <Row>
                                <Col>
                                    <div className="home_header">
                                        {/* <img src={BackIcon} className="back mt-1" alt="" /> */}
                                        <a href="https://jelliepets.com/JellieID">
                                            <img src={HeaderLogo} className="logo" alt="" />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        {pet && Object.keys(pet).length > 0 && (
                            <React.Fragment>
                                {pet?.missing && (
                                    <React.Fragment>
                                        <div className="missing_pet">
                                            <div
                                                className="pt-1 pb-1"
                                                style={{
                                                    width: '100%',
                                                    margin: '0',
                                                    padding: '0',
                                                    textAlign: 'center',
                                                    background: '#f5813c',
                                                    color: '#fff',
                                                }}
                                            >
                                                <h2 className="missing_pet_text">MISSING PET</h2>
                                            </div>
                                            <div className="text-center mb-3">
                                                {pet?.date_missing !== '' &&
                                                    pet?.date_missing !== null ? (
                                                    <p
                                                        className="mt-2"
                                                        style={{ fontSize: '14px', fontWeight: '500' }}
                                                    >
                                                        This pet has been reported missing since{' '}
                                                        {moment(pet?.date_missing).format('MM-DD-YYYY')}
                                                    </p>
                                                ) : (
                                                    <p
                                                        className="mt-2"
                                                        style={{ fontSize: '14px', fontWeight: '500' }}
                                                    >
                                                        This pet has been reported missing since ----
                                                    </p>
                                                )}
                                                <span
                                                    style={{
                                                        textDecoration: 'underline',
                                                        color: '#4674C1',
                                                    }}
                                                    onClick={sendGpsLocation}
                                                >
                                                    Find nearby support resources
                                                </span>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                        <Container fluid>
                            <div className="pet_profile_page">
                                <div className="pet_details">
                                    <div className="pet_details_image">
                                        <Link to="/pet/image">
                                            <img
                                                src={petFilename ? petImageUrl : NoPetImage}
                                                style={{
                                                    border: `${pet?.missing === true ? '2px solid #F5813C' : 'none'
                                                        } `,
                                                    borderRadius: '50%',
                                                }}
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                    <div className="pet_details_name">
                                        <div className="pet_details_name_text">
                                            <h2 className="pet_name">{pet?.name}</h2>
                                            <div className="profile_qr">
                                                <Link to={`/pet/qr`} onClick={(e) => saveStorage()}>
                                                    <img src={qrcode} alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                        <span className="pet_sex">
                                            {pet?.gender && pet.gender} {pet?.breed && pet?.breed}
                                        </span>
                                        <span className="pet_age">
                                            {petAge && petAge.toFixed(1)} yr old{' '}
                                            <span className="human">
                                                {humanAge ? `(~${humanAge.toFixed(1)} yr human)` : ``}
                                            </span>{' '}
                                        </span>
                                    </div>
                                </div>
                                {pet?.headline !== null && pet?.headline !== '' ? (
                                    <div className="enjoy_long_hunt">
                                        <span>{pet?.headline}</span>
                                    </div>
                                ) : (
                                    <div style={{ borderBottom: '1px solid #E5E5E5' }}></div>
                                )}

                                {pet?.display_physical_stats && (
                                    <React.Fragment>
                                        <Collapsible
                                            trigger={[
                                                <svg
                                                    width="20"
                                                    height="15"
                                                    viewBox="0 0 20 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.8866 1.56824C11.1962 0.440984 12.3802 -0.228506 13.5329 0.0728597C14.6857 0.376133 15.3692 1.53582 15.0596 2.66308C14.75 3.79225 13.566 4.46174 12.4152 4.15846C11.2624 3.8571 10.5789 2.69741 10.8866 1.56824ZM6.46634 0.0728597C7.61912 -0.230413 8.80305 0.440984 9.11071 1.56824C9.41838 2.6955 8.73489 3.8571 7.58407 4.15846C6.43129 4.46174 5.24736 3.79034 4.9397 2.66308C4.63008 1.53582 5.31552 0.376133 6.46634 0.0728597ZM0.632371 6.97185C-0.21079 6.14595 -0.21079 4.80697 0.632371 3.97917C1.47553 3.15137 2.8425 3.15328 3.68566 3.97917C4.52883 4.80506 4.52883 6.14404 3.68566 6.97185C2.8425 7.79965 1.47553 7.79774 0.632371 6.97185ZM3.62141 10.9239C3.62141 8.67323 6.47803 6.84787 10.0006 6.84787C10.4056 6.84787 10.799 6.87266 11.1826 6.91844C10.7406 8.67323 10.0843 10.0122 9.20808 10.9545C8.00857 12.2438 6.4605 12.7779 4.67876 13.1689C4.01086 12.5261 3.62141 11.7555 3.62141 10.9239ZM9.99866 15C8.97441 15 8.00662 14.8455 7.14983 14.5708C8.4506 14.1112 9.67931 13.436 10.725 12.3106C11.8447 11.107 12.6567 9.4934 13.1824 7.39338C15.0927 8.0972 16.3779 9.4152 16.3779 10.9239C16.3779 13.1746 13.5232 15 9.99866 15ZM19.3669 6.97185C18.5237 7.79774 17.1568 7.79774 16.3117 6.97185C15.4685 6.14595 15.4685 4.80697 16.3117 3.97917C17.1548 3.15328 18.5218 3.15328 19.3669 3.97917C20.212 4.80506 20.2101 6.14595 19.3669 6.97185Z"
                                                        fill="#F5813C"
                                                    />
                                                </svg>,
                                                <p className="collapse_name">Physical</p>,
                                                <svg
                                                    className="down_arrow"
                                                    width="12"
                                                    height="8"
                                                    viewBox="0 0 12 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                                                        fill="#616161"
                                                    />
                                                </svg>,
                                            ]}
                                        >
                                            <div className="collapse_inner_content">
                                                {pet?.weight === null || pet?.weight === '' ? null : (
                                                    <div className="profile_collapse_row">
                                                        <span className="profile_collapse_row_title">
                                                            Weight:
                                                        </span>
                                                        <span
                                                            className="profile_collapse_row_content"
                                                            style={{ width: '32px' }}
                                                        >
                                                            {pet.weight.toFixed(1)}
                                                        </span>
                                                        <span style={{ width: '20px', textAlign: 'right' }}>
                                                            lb
                                                        </span>
                                                    </div>
                                                )}

                                                {pet?.height === null || pet?.height === '' ? null : (
                                                    <div className="profile_collapse_row">
                                                        <span className="profile_collapse_row_title">
                                                            Height:
                                                        </span>
                                                        <span
                                                            className="profile_collapse_row_content"
                                                            style={{ width: '32px' }}
                                                        >
                                                            {' '}
                                                            {pet.height.toFixed(1)}
                                                        </span>
                                                        <span style={{ width: '20px', textAlign: 'right' }}>
                                                            in
                                                        </span>
                                                    </div>
                                                )}

                                                {pet?.length === null || pet?.length === '' ? null : (
                                                    <div className="profile_collapse_row">
                                                        <span className="profile_collapse_row_title">
                                                            Length:
                                                        </span>
                                                        <span
                                                            className="profile_collapse_row_content"
                                                            style={{ width: '32px' }}
                                                        >
                                                            {pet.length.toFixed(1)}
                                                        </span>
                                                        <span style={{ width: '20px', textAlign: 'right' }}>
                                                            lb
                                                        </span>
                                                    </div>
                                                )}

                                                {pet?.coat_color === null ||
                                                    pet?.coat_color === '' ? null : (
                                                    <div className="profile_collapse_row">
                                                        <span className="profile_collapse_row_title">
                                                            Coat color:
                                                        </span>
                                                        <span className="profile_collapse_row_content">
                                                            {pet.coat_color}
                                                        </span>
                                                    </div>
                                                )}

                                                {pet?.eye_color === null ||
                                                    pet?.eye_color === '' ? null : (
                                                    <div className="profile_collapse_row">
                                                        <span className="profile_collapse_row_title">
                                                            Eye color:
                                                        </span>
                                                        <span className="profile_collapse_row_content">
                                                            {pet.eye_color}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </Collapsible>
                                    </React.Fragment>
                                )}

                                {pet?.display_health_info && (
                                    <React.Fragment>
                                        <Collapsible
                                            trigger={[
                                                <svg
                                                    width="18"
                                                    height="16"
                                                    viewBox="0 0 18 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M7.695 14.849L9 16L10.305 14.8578L10.3405 14.8265C12.9511 12.5281 15.0592 10.6722 16.3978 8.89861H12.9599H12.5263L12.3505 8.50225L11.8799 7.44133L10.3293 10.937L9.65747 12.4517L9.09312 10.8938L7.17056 5.58651L6.01932 8.47851L5.85209 8.89861H5.39993H1.60269C2.94551 10.6765 5.06218 12.5361 7.68359 14.839L7.695 14.849ZM0.739795 7.56527C0.258771 6.66094 0 5.7541 0 4.79564C0 2.11008 2.178 0 4.95 0C6.516 0 8.019 0.706267 9 1.82234C9.981 0.706267 11.484 0 13.05 0C15.822 0 18 2.11008 18 4.79564C18 5.75402 17.7413 6.6608 17.2603 7.56527H13.3935L12.4893 5.52684L11.8799 4.15298L11.2705 5.52684L9.78238 8.88175L7.82673 3.48314L7.22929 1.83389L6.58053 3.46363L4.94776 7.56527H0.739795Z"
                                                        fill="#F5813C"
                                                    />
                                                </svg>,

                                                <p className="collapse_name">Medical</p>,
                                                <svg
                                                    className="down_arrow"
                                                    width="12"
                                                    height="8"
                                                    viewBox="0 0 12 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                                                        fill="#616161"
                                                    />
                                                </svg>,
                                            ]}
                                        >
                                            <div className="collapse_inner_content">
                                                {pet?.sex_health === null ||
                                                    pet?.sex_health === '' ? null : (
                                                    <div className="profile_collapse_row">
                                                        <span className="profile_collapse_row_title">
                                                            Reproductive:
                                                        </span>
                                                        <span className="profile_collapse_row_content">
                                                            {pet.sex_health}
                                                        </span>
                                                    </div>
                                                )}

                                                {pet?.microchipped === null ||
                                                    pet?.microchipped === '' ? null : (
                                                    <div className="profile_collapse_row">
                                                        <span className="profile_collapse_row_title">
                                                            Microchip:
                                                        </span>
                                                        <span className="profile_collapse_row_content">
                                                            {pet?.microchipped ? `Yes` : `No`}
                                                        </span>
                                                    </div>
                                                )}

                                                {pet?.allergies === null ||
                                                    pet?.allergies === '' ? null : (
                                                    <React.Fragment>
                                                        <div className="profile_collapse_row">
                                                            <span className="profile_collapse_row_title">
                                                                Allergies:
                                                            </span>
                                                        </div>
                                                        <span className="profile_collapse_row_content mb-4">
                                                            {pet.allergies}
                                                        </span>
                                                    </React.Fragment>
                                                )}

                                                {pet?.medical_conditions === null ||
                                                    pet?.medical_conditions === '' ? null : (
                                                    <React.Fragment>
                                                        <div className="profile_collapse_row">
                                                            <span className="profile_collapse_row_title">
                                                                Conditions:
                                                            </span>
                                                        </div>
                                                        <span className="profile_collapse_row_content mb-4">
                                                            {pet.medical_conditions}
                                                        </span>
                                                    </React.Fragment>
                                                )}

                                                {pet?.remedies === null ||
                                                    pet?.remedies === '' ? null : (
                                                    <React.Fragment>
                                                        <div className="profile_collapse_row">
                                                            <span className="profile_collapse_row_title">
                                                                Remedies:
                                                            </span>
                                                        </div>
                                                        <span className="profile_collapse_row_content mb-4">
                                                            {pet.remedies}
                                                        </span>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </Collapsible>
                                    </React.Fragment>
                                )}

                                {pet?.display_genetics && (
                                    <React.Fragment>
                                        <Collapsible
                                            trigger={[
                                                <svg
                                                    width="14"
                                                    height="20"
                                                    viewBox="0 0 14 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14 1.25V0H11.2V1.25C11.2 2.5 9.149 3.4125 7 4.25937C4.851 3.4125 2.8 2.5 2.8 1.25V0H0V1.25C0 3.35313 1.6765 4.6375 3.717 5.625C1.6765 6.6125 0 7.89687 0 10C0 12.1031 1.6765 13.3875 3.717 14.375C1.6765 15.3625 0 16.6469 0 18.75V20H2.8V18.75C2.8 17.5 4.851 16.5875 7 15.7406C9.149 16.5875 11.2 17.5 11.2 18.75V20H14V18.75C14 16.6469 12.3235 15.3625 10.283 14.375C12.3235 13.3875 14 12.1031 14 10C14 7.89687 12.3235 6.6125 10.283 5.625C12.3235 4.6375 14 3.35313 14 1.25ZM11.2 10C11.2 11.25 9.149 12.1625 7 13.0094C4.851 12.1625 2.8 11.25 2.8 10C2.8 8.75 4.851 7.8375 7 6.99062C9.149 7.8375 11.2 8.75 11.2 10Z"
                                                        fill="#F5813C"
                                                    />
                                                </svg>,

                                                <p className="collapse_name">Genetics</p>,
                                                <svg
                                                    className="down_arrow"
                                                    width="12"
                                                    height="8"
                                                    viewBox="0 0 12 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                                                        fill="#616161"
                                                    />
                                                </svg>,
                                            ]}
                                        >
                                            <div
                                                className="collapse_inner_content"
                                                style={{ marginLeft: '30px' }}
                                            >
                                                {/* <div className="profile_collapse_row"><span className="profile_collapse_row_title">45.2% -</span><span className="profile_collapse_row_content">Labrador Retriever</span></div>
                              <div className="profile_collapse_row"><span className="profile_collapse_row_title">25.0% -</span><span className="profile_collapse_row_content">American Foxhound</span></div>
                              <div className="profile_collapse_row"><span className="profile_collapse_row_title">25.0% -</span><span className="profile_collapse_row_content">Terrier</span></div>
                              <div className="profile_collapse_row"><span className="profile_collapse_row_title">4.8% -</span><span className="profile_collapse_row_content">Supermutt</span></div> */}

                                                {pet && Object.keys(pet).length > 0 && (
                                                    <React.Fragment>
                                                        {pet?.genetics !== null &&
                                                            Object.keys(pet?.genetics).length > 0 ? (
                                                            <React.Fragment>
                                                                {Object.entries(pet?.genetics).map(
                                                                    ([key, value]) => {
                                                                        return (
                                                                            <React.Fragment>
                                                                                {
                                                                                    key !== null && key !== "null" && key !== "" &&
                                                                                    <React.Fragment>
                                                                                        <div
                                                                                            key={key}
                                                                                            className="profile_collapse_row_genetics"
                                                                                        >
                                                                                            <span className="profile_collapse_row_title_genetics">
                                                                                                {value} -
                                                                                            </span>
                                                                                            <span className="profile_collapse_row_content">
                                                                                                <div className=" genetics_wrapping">
                                                                                                    {key}
                                                                                                </div>
                                                                                            </span>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </React.Fragment>
                                                                        );
                                                                    }
                                                                )}
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <h6
                                                                    className="text-left mt-2"
                                                                    style={{ color: '#616161' }}
                                                                >
                                                                    No Genetics
                                                                </h6>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </Collapsible>
                                    </React.Fragment>
                                )}

                                {pet?.display_documents && (
                                    <React.Fragment>
                                        <Collapsible
                                            trigger={[
                                                <svg
                                                    width="15"
                                                    height="20"
                                                    viewBox="0 0 15 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.6577 4.60403L10.7824 0.396127C10.7235 0.274846 10.6348 0.173647 10.5262 0.103743C10.4175 0.0338401 10.2932 -0.00203652 10.1669 8.9272e-05H2.96345C2.17749 8.9272e-05 1.42373 0.339107 0.867974 0.942563C0.31222 1.54602 0 2.36448 0 3.2179V16.7822C0 17.6356 0.31222 18.4541 0.867974 19.0575C1.42373 19.661 2.17749 20 2.96345 20H12.0362C12.8221 20 13.5759 19.661 14.1316 19.0575C14.6874 18.4541 14.9996 17.6356 14.9996 16.7822V5.27234C15.0038 5.13775 14.9742 5.00446 14.9139 4.88673C14.8537 4.76901 14.7651 4.67129 14.6577 4.60403V4.60403ZM10.8736 2.57434L12.6516 4.48027H10.8736V2.57434ZM13.6319 16.7822C13.6319 17.2417 13.4637 17.6824 13.1645 18.0074C12.8652 18.3323 12.4594 18.5149 12.0362 18.5149H2.96345C2.54024 18.5149 2.13437 18.3323 1.83512 18.0074C1.53586 17.6824 1.36775 17.2417 1.36775 16.7822V3.2179C1.36775 2.75837 1.53586 2.31766 1.83512 1.99272C2.13437 1.66778 2.54024 1.48523 2.96345 1.48523H9.50583V5.00007C9.50583 5.26266 9.6019 5.51449 9.7729 5.70017C9.9439 5.88585 10.1758 5.99016 10.4177 5.99016H13.6319V16.7822Z"
                                                        fill="#F5813C"
                                                    />
                                                    <path
                                                        d="M4.35398 7.35154H6.56517C6.74654 7.35154 6.92049 7.2733 7.04874 7.13404C7.17699 6.99478 7.24904 6.80591 7.24904 6.60897C7.24904 6.41202 7.17699 6.22315 7.04874 6.08389C6.92049 5.94463 6.74654 5.86639 6.56517 5.86639H4.35398C4.1726 5.86639 3.99866 5.94463 3.87041 6.08389C3.74216 6.22315 3.6701 6.41202 3.6701 6.60897C3.6701 6.80591 3.74216 6.99478 3.87041 7.13404C3.99866 7.2733 4.1726 7.35154 4.35398 7.35154Z"
                                                        fill="#F5813C"
                                                    />
                                                    <path
                                                        d="M3.6701 9.33171C3.6701 9.52865 3.74216 9.71753 3.87041 9.85679C3.99866 9.99605 4.1726 10.0743 4.35398 10.0743H10.6456C10.827 10.0743 11.0009 9.99605 11.1292 9.85679C11.2574 9.71753 11.3295 9.52865 11.3295 9.33171C11.3295 9.13477 11.2574 8.9459 11.1292 8.80664C11.0009 8.66738 10.827 8.58914 10.6456 8.58914H4.35398C4.1726 8.58914 3.99866 8.66738 3.87041 8.80664C3.74216 8.9459 3.6701 9.13477 3.6701 9.33171Z"
                                                        fill="#F5813C"
                                                    />
                                                    <path
                                                        d="M10.6456 14.0347H4.35398C4.1726 14.0347 3.99866 14.1129 3.87041 14.2522C3.74216 14.3914 3.6701 14.5803 3.6701 14.7772C3.6701 14.9742 3.74216 15.1631 3.87041 15.3023C3.99866 15.4416 4.1726 15.5198 4.35398 15.5198H10.6456C10.827 15.5198 11.0009 15.4416 11.1292 15.3023C11.2574 15.1631 11.3295 14.9742 11.3295 14.7772C11.3295 14.5803 11.2574 14.3914 11.1292 14.2522C11.0009 14.1129 10.827 14.0347 10.6456 14.0347V14.0347Z"
                                                        fill="#F5813C"
                                                    />
                                                </svg>,

                                                <p className="collapse_name">Documents</p>,
                                                <svg
                                                    className="down_arrow"
                                                    width="12"
                                                    height="8"
                                                    viewBox="0 0 12 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                                                        fill="#616161"
                                                    />
                                                </svg>,
                                            ]}
                                        >
                                            <div className="collapse_inner_content">
                                                {/* <div className="profile_collapse_row"><span className="profile_collapse_row_document"><a href="">GND Health Certificate</a></span></div>
                              <div className="profile_collapse_row"><span className="profile_collapse_row_document"><a href="">Vaccination proof</a></span></div>
                              <div className="profile_collapse_row"><span className="profile_collapse_row_document"><a href="">ESA certificate</a></span></div> */}

                                                {pet && Object.keys(pet).length > 0 && (
                                                    <React.Fragment>
                                                        {pet?.pet_images &&
                                                            pet?.pet_images?.length > 0 &&
                                                            pet?.pet_images?.filter(
                                                                (data) => data?.type === 'DOCUMENT'
                                                            )?.length > 0 ? (
                                                            <React.Fragment>
                                                                {pet?.pet_images
                                                                    ?.filter((data) => data?.type === 'DOCUMENT')
                                                                    ?.map((doc) => {
                                                                        return (
                                                                            <div
                                                                                key={doc.id}
                                                                                className="profile_collapse_row"
                                                                            >
                                                                                <span className="profile_collapse_row_document">
                                                                                    <Link
                                                                                        to={`/document/${doc?.id}`}
                                                                                        onClick={(e) => saveStorage()}
                                                                                    >
                                                                                        {doc?.src}
                                                                                    </Link>
                                                                                </span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <h6
                                                                    className="text-left mt-2"
                                                                    style={{ color: '#616161' }}
                                                                >
                                                                    No Documents
                                                                </h6>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </Collapsible>
                                    </React.Fragment>
                                )}

                                {pet?.display_parent_names && (
                                    <React.Fragment>
                                        <Collapsible
                                            trigger={[
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.0227 11.3608C12.0147 11.3608 13.6296 9.74593 13.6296 7.75388C13.6296 5.76184 12.0147 4.14697 10.0227 4.14697C8.03064 4.14697 6.41577 5.76184 6.41577 7.75388C6.41577 9.74593 8.03064 11.3608 10.0227 11.3608Z"
                                                        fill="#F5813C"
                                                    />
                                                    <path
                                                        d="M10 0C4.49244 0 0 4.47084 0 10C0 15.5292 4.49244 20 10 20C15.5076 20 20 15.5076 20 10C20 4.49244 15.5076 0 10 0ZM15.8747 16.3067C15.1404 14.3413 13.5421 12.2462 10 12.2462C6.47948 12.2462 4.85961 14.3413 4.12527 16.3067C2.4406 14.73 1.36069 12.4838 1.36069 10C1.36069 5.24838 5.22678 1.36069 10 1.36069C14.7732 1.36069 18.6393 5.22678 18.6393 10C18.6393 12.4838 17.5594 14.73 15.8747 16.3067Z"
                                                        fill="#F5813C"
                                                    />
                                                </svg>,

                                                <p className="collapse_name">People</p>,
                                                <svg
                                                    className="down_arrow"
                                                    width="12"
                                                    height="8"
                                                    viewBox="0 0 12 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                                                        fill="#616161"
                                                    />
                                                </svg>,
                                            ]}
                                        >
                                            <div className="collapse_inner_content">
                                                {/* <div className="profile_collapse_row"><span className="profile_collapse_row_document"><a href="">Sarah Banfield</a></span></div>
                              <div className="profile_collapse_row"><span className="profile_collapse_row_document"><a href="">David Williams</a></span></div>
                              <div className="profile_collapse_row"><span className="profile_collapse_row_you">You</span></div> */}

                                                {pet && Object.keys(pet).length > 0 && (
                                                    <React.Fragment>
                                                        {pet?.pet_owners && pet?.pet_owners.length > 0 && (
                                                            <React.Fragment>
                                                                {pet?.pet_owners
                                                                    .filter((data) => data?.accepted === true)
                                                                    ?.map((owner) => {
                                                                        return (
                                                                            <div
                                                                                key={owner?.owner_user?.id}
                                                                                className="profile_collapse_row"
                                                                                onClick={clearUserImageState}
                                                                            >
                                                                                <span className="profile_collapse_row_document">
                                                                                    <Link
                                                                                        to={`/person/owner`}
                                                                                        onClick={(e) =>
                                                                                            saveStorage(
                                                                                                owner?.owner_user?.email
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            owner?.owner_user?.first_name === null || owner?.owner_user?.first_name === "null" || owner?.owner_user?.first_name === "" ?
                                                                                                `${owner?.owner_user?.email.split('@')[0]}`
                                                                                                :
                                                                                                `${owner?.owner_user?.first_name}`
                                                                                        }
                                                                                    </Link>
                                                                                </span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                                {pet && Object.keys(pet).length > 0 && (
                                                    <React.Fragment>
                                                        {pet?.pet_co_owners &&
                                                            pet?.pet_co_owners?.length > 0 && (
                                                                <React.Fragment>
                                                                    {pet?.pet_co_owners
                                                                        ?.filter((data) => data?.accepted === true)
                                                                        ?.map((data) => {
                                                                            return (
                                                                                <div
                                                                                    key={data?.co_owned_user?.id}
                                                                                    className="profile_collapse_row"
                                                                                    onClick={clearUserImageState}
                                                                                >
                                                                                    <span className="profile_collapse_row_document">
                                                                                        <Link
                                                                                            to={`/person/co-owner`}
                                                                                            onClick={(e) =>
                                                                                                saveStorage(
                                                                                                    data?.co_owned_user?.email
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                data?.co_owned_user?.first_name === null || data?.co_owned_user?.first_name === "null" || data?.co_owned_user?.first_name === "" ?
                                                                                                    `${data?.co_owned_user?.email.split('@')[0]}`
                                                                                                    :
                                                                                                    `${data?.co_owned_user?.first_name}`
                                                                                            }
                                                                                        </Link>
                                                                                    </span>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                </React.Fragment>
                                                            )}
                                                    </React.Fragment>
                                                )}
                                                {pet && Object.keys(pet).length > 0 && (
                                                    <React.Fragment>
                                                        {pet?.pet_hosts && pet?.pet_hosts.length > 0 && (
                                                            <React.Fragment>
                                                                {pet?.pet_hosts
                                                                    ?.filter((data) => data?.accepted === true)
                                                                    ?.map((host) => {
                                                                        return (
                                                                            <div
                                                                                key={host?.host_user?.id}
                                                                                className="profile_collapse_row"
                                                                                onClick={clearUserImageState}
                                                                            >
                                                                                <span className="profile_collapse_row_document">
                                                                                    <Link
                                                                                        to={`/person/host`}
                                                                                        onClick={(e) =>
                                                                                            saveStorage(
                                                                                                host?.host_user?.email
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            host?.host_user?.first_name === null || host?.host_user?.first_name === "" || host?.host_user?.first_name === "null" ?
                                                                                                `${host?.host_user?.email.split('@')[0]}`
                                                                                                :
                                                                                                `${host?.host_user?.first_name}`
                                                                                        }
                                                                                    </Link>
                                                                                </span>
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </Collapsible>
                                    </React.Fragment>
                                )}

                                {pet?.display_other_account && (
                                    <React.Fragment>
                                        <Collapsible
                                            trigger={[
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12.2592 8.12785C12.2664 8.25723 12.2664 8.39422 12.2378 8.52359C12.2378 8.5312 12.2307 8.53881 12.2307 8.53881C12.1807 8.76712 12.0736 8.98021 11.9093 9.16286C11.4309 9.67276 10.6525 9.67276 10.1741 9.16286C9.68134 8.63775 8.98866 8.43988 8.34598 8.56925C8.2103 8.5997 8.08176 8.63775 7.95323 8.69102C7.71043 8.79756 7.48192 8.95738 7.28198 9.16286L5.36821 11.21L3.0474 13.6834C2.25475 14.5282 2.25475 15.9132 3.0474 16.758C3.81862 17.5799 5.16112 17.5799 5.93234 16.758L7.69615 14.8782C8.43881 15.4186 9.2743 15.7839 10.1598 15.9437L7.66759 18.5997C6.78925 19.5358 5.63956 20 4.48987 20C3.34018 20 2.19048 19.5358 1.31215 18.5997C-0.437383 16.7352 -0.437383 13.691 1.31215 11.8265L5.54673 7.31355C6.42507 6.37747 7.57476 5.91324 8.72445 5.91324C9.87414 5.91324 11.0238 6.37747 11.9022 7.31355C11.995 7.41248 12.0664 7.52664 12.1235 7.6484C12.1307 7.66362 12.145 7.68645 12.1521 7.70167C12.2021 7.82344 12.2307 7.94521 12.2449 8.06697C12.2592 8.0898 12.2592 8.11263 12.2592 8.12785ZM18.6861 1.4003C17.8363 0.494673 16.708 0 15.5083 0C14.3087 0 13.1804 0.494673 12.3306 1.4003L9.8313 4.06393C10.7025 4.22374 11.5451 4.58143 12.2949 5.12938L14.0587 3.24962C14.83 2.4277 16.1725 2.4277 16.9437 3.24962C17.3293 3.66058 17.5435 4.20852 17.5435 4.78691C17.5435 5.3653 17.3293 5.91324 16.9437 6.3242L16.1653 7.15373L12.7162 10.8371C11.945 11.6591 10.6025 11.6591 9.8313 10.8371C9.35285 10.3272 8.57449 10.3272 8.09605 10.8371C7.6176 11.347 7.6176 12.1766 8.09605 12.6865C8.94582 13.5921 10.0741 14.0868 11.2738 14.0868C12.4734 14.0868 13.6017 13.5921 14.4515 12.6865L17.9077 9.00304L18.6861 8.17352C19.5358 7.26788 20 6.06545 20 4.78691C20 3.50837 19.5358 2.30594 18.6861 1.4003Z"
                                                        fill="#F5813C"
                                                    />
                                                </svg>,

                                                <p className="collapse_name">Other accounts</p>,
                                                <svg
                                                    className="down_arrow"
                                                    width="12"
                                                    height="8"
                                                    viewBox="0 0 12 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                                                        fill="#616161"
                                                    />
                                                </svg>,
                                            ]}
                                        >
                                            <div className="collapse_inner_content">
                                                {/* <div className="profile_collapse_row"><span className="profile_collapse_row_document"><a href="">Embark Profile</a></span></div> */}

                                                {pet && Object.keys(pet).length > 0 && (
                                                    <React.Fragment>
                                                        {pet?.pet_links && pet?.pet_links?.length > 0 ? (
                                                            <React.Fragment>
                                                                {pet?.pet_links?.map((data) => {
                                                                    return (
                                                                        <div
                                                                            className="profile_collapse_row"
                                                                            key={data?.id}
                                                                        >
                                                                            <span className="profile_collapse_row_document">
                                                                                <p
                                                                                    onClick={(e) =>
                                                                                        redirectUrl(data?.url)
                                                                                    }
                                                                                >
                                                                                    {data?.name}
                                                                                </p>
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <h6
                                                                    className="text-left mt-2"
                                                                    style={{ color: '#616161' }}
                                                                >
                                                                    No Accounts
                                                                </h6>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </Collapsible>
                                    </React.Fragment>
                                )}

                                {pet?.display_routines && (
                                    <React.Fragment>
                                        <Collapsible
                                            trigger={[
                                                <svg
                                                    width="16"
                                                    height="17"
                                                    viewBox="0 0 16 17"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M2.69267 14.2608L0.608663 16.3448L0.63645 10.8152L6.16603 10.7874L4.08202 12.8715C6.35838 15.1478 10.077 15.1291 12.3764 12.8298C13.078 12.1282 13.573 11.2893 13.8401 10.4033L15.8583 10.3931C15.5403 11.784 14.8425 13.1146 13.7518 14.2052C10.6814 17.2757 5.73241 17.3005 2.69267 14.2608ZM12.4181 4.53541C10.1417 2.25904 6.42306 2.27773 4.1237 4.57709C3.42208 5.27871 2.92713 6.11752 2.66002 7.00357L0.641729 7.01372C0.95975 5.62285 1.65762 4.29227 2.74825 3.20164C5.81869 0.131197 10.7677 0.106327 13.8074 3.14607L15.8914 1.06206L15.8636 6.59163L10.3341 6.61942L12.4181 4.53541Z"
                                                        fill="#F5813C"
                                                    />
                                                </svg>,
                                                <p className="collapse_name">Current routines</p>,
                                                <svg
                                                    className="down_arrow"
                                                    width="12"
                                                    height="8"
                                                    viewBox="0 0 12 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                                                        fill="#616161"
                                                    />
                                                </svg>,
                                            ]}
                                        >
                                            <div className="collapse_inner_content">
                                                {/* <div className="routine_image_row">
                            <span className="routine_image_row_title">Breakfast routine</span>
                            <li>1 cup Blue Buffalo Adult Chicken</li>
                            <li>Mon, Tue, Wed, Thu, Fri</li>
                          </div>
                          <div className="routine_image_row">
                            <span className="routine_image_row_title">Deworming pills</span>
                            <li>1 dose Levamisole</li>
                            <li>4th day, every month</li>
                          </div>
                          <div className="routine_image_row">
                            <span className="routine_image_row_title">Evening walk</span>
                            <li>2 mi Walk</li>
                            <li>5pm, Everyday</li>
                          </div> */}

                                                {pet && Object.keys(pet).length > 0 && (
                                                    <React.Fragment>
                                                        {pet?.routines && pet?.routines?.length > 0 ? (
                                                            <React.Fragment>
                                                                {pet?.routines.map((routine) => {
                                                                    const data = routine?.days_of_week.toString();
                                                                    const dataLength = data?.length;
                                                                    return (
                                                                        <div className="routine_image_row">
                                                                            <span className="routine_image_row_title">
                                                                                {routine?.name}:
                                                                            </span>
                                                                            <li>
                                                                                {routine?.type === 'Medicine'
                                                                                    ? `${routine?.serving_quantity} Dose`
                                                                                    : null}
                                                                                {routine?.type === 'Food' ||
                                                                                    routine?.type === 'Treat' ||
                                                                                    routine?.type === 'Water'
                                                                                    ? `${routine?.serving_quantity} ${routine?.serving_size}`
                                                                                    : null}
                                                                                {routine?.exercise_type === 'Play' ||
                                                                                    routine?.exercise_type === 'Swim' || routine?.exercise_type === 'play' || routine?.exercise_type === 'swim'
                                                                                    ? `${routine?.duration} min`
                                                                                    : null}
                                                                                {routine?.exercise_type === 'Run' ||
                                                                                    routine?.exercise_type === 'Walk' || routine?.exercise_type === 'run' || routine?.exercise_type === 'walk'
                                                                                    ? `${routine?.distance} mi ${routine?.exercise_type}`
                                                                                    : null}
                                                                            </li>
                                                                            <li>
                                                                                {routine?.repeat_daily
                                                                                    ? 'Everyday'
                                                                                    : routine?.repeat_monthly
                                                                                        ? `${routine?.day_of_month}th day, every month`
                                                                                        : dataLength > 2
                                                                                            ? routine?.days_of_week
                                                                                                .toString()
                                                                                                .slice(1, Number(dataLength) - 1)
                                                                                                .replace(/,/g, ', ')
                                                                                            : '-'}
                                                                            </li>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <h6
                                                                    className="text-left mt-2"
                                                                    style={{ color: '#616161' }}
                                                                >
                                                                    No Routines.
                                                                </h6>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </Collapsible>
                                    </React.Fragment>
                                )}

                                {pet?.display_products && (
                                    <React.Fragment>
                                        <Collapsible
                                            trigger={[
                                                <svg
                                                    width="20"
                                                    height="21"
                                                    viewBox="0 0 20 21"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M9.8477 1.67675L8.18221 2.98003L6.46089 1.625L4.79075 2.98003L3.39508 2.0955L1.25972 18.6475C1.17133 19.3297 1.69703 19.932 2.37625 19.932H9.78722H9.90818H17.3191C17.9984 19.932 18.5241 19.3297 18.4357 18.6475L16.305 2.0955L14.9093 2.98003L13.2392 1.625L11.5132 2.98003L9.8477 1.67675Z"
                                                        stroke="#F5813C"
                                                        stroke-width="1.25"
                                                        stroke-miterlimit="10"
                                                    />
                                                    <path
                                                        d="M10.3135 9.11931C10.4763 8.50766 11.0997 8.14538 11.7045 8.31006C12.3093 8.47473 12.6675 9.10519 12.5046 9.71684C12.3418 10.3285 11.7184 10.6908 11.1136 10.5261C10.5135 10.3567 10.1553 9.73095 10.3135 9.11931ZM7.992 8.30535C8.59679 8.14068 9.22018 8.50296 9.38301 9.1146C9.54584 9.72625 9.18762 10.3567 8.58283 10.5214C7.97805 10.6861 7.35465 10.3238 7.19182 9.71213C7.02899 9.10049 7.38721 8.47002 7.992 8.30535ZM4.9262 12.0505C4.48424 11.6035 4.48424 10.8743 4.9262 10.4273C5.36816 9.98031 6.08925 9.98031 6.53121 10.4273C6.97317 10.8743 6.97317 11.6035 6.53121 12.0505C6.08925 12.4975 5.36816 12.4975 4.9262 12.0505ZM6.49864 14.1912C6.49864 12.968 8.00131 11.9799 9.84823 11.9799C10.0622 11.9799 10.2669 11.994 10.467 12.0176C10.2344 12.968 9.8901 13.6972 9.42953 14.2054C8.80149 14.9064 7.98735 15.1934 7.0476 15.4051C6.70334 15.0617 6.49864 14.6429 6.49864 14.1912ZM9.84823 16.4026C9.30858 16.4026 8.80149 16.3179 8.35022 16.172C9.0341 15.9227 9.68075 15.5557 10.2297 14.944C10.8159 14.2901 11.2439 13.4149 11.5184 12.2763C12.5233 12.6574 13.1978 13.3726 13.1978 14.1912C13.2025 15.4145 11.6998 16.4026 9.84823 16.4026ZM14.7703 12.0505C14.3283 12.4975 13.6072 12.4975 13.1653 12.0505C12.7233 11.6035 12.7233 10.8743 13.1653 10.4273C13.6072 9.98031 14.3283 9.98031 14.7703 10.4273C15.2122 10.8743 15.2122 11.5988 14.7703 12.0505Z"
                                                        fill="#F5813C"
                                                    />
                                                </svg>,

                                                <p className="collapse_name">Recent products</p>,
                                                <svg
                                                    className="down_arrow"
                                                    width="12"
                                                    height="8"
                                                    viewBox="0 0 12 8"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                                                        fill="#616161"
                                                    />
                                                </svg>,
                                            ]}
                                        >
                                            <div className="collapse_inner_content last_collapse">
                                                {/* <div className="profile_collapse_row"><span className="profile_collapse_row_document"><a href="">Blue Buffalo Adult Chicken</a></span></div>
                              <div className="profile_collapse_row"><span className="profile_collapse_row_document"><a href="">Greenies Original Treats</a></span></div>
                              <div className="profile_collapse_row"><span className="profile_collapse_row_document"><a href="">Levamisole</a></span></div> */}

                                                {pet && Object.keys(pet).length > 0 && (
                                                    <React.Fragment>
                                                        {pet?.routine_tasks?.length > 0 ? (
                                                            <React.Fragment>
                                                                {pet.routine_tasks.map((data) => {
                                                                    return (
                                                                        <div
                                                                            key={data?.id}
                                                                            className="profile_collapse_row"
                                                                            onClick={clearUserImageState}
                                                                        >
                                                                            <span className="profile_collapse_row_document">
                                                                                <Link
                                                                                    to={`/product/${data?.pet_product?.id}`}
                                                                                    onClick={(e) => saveStorage()}
                                                                                >
                                                                                    {data?.pet_product?.name}
                                                                                </Link>
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <h6
                                                                    className="text-left mt-2"
                                                                    style={{ color: '#616161' }}
                                                                >
                                                                    No Products
                                                                </h6>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </Collapsible>
                                    </React.Fragment>
                                )}
                            </div>
                        </Container>

                        {pet?.request_parent_or_host && (
                            <React.Fragment>
                                <div className="be_host mb-3 ml-3">
                                    <Link
                                        to={`/petrequest/parent`}
                                        onClick={(e) => saveStorage()}
                                        style={{
                                            textDecoration: 'none',
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span>
                                            <svg
                                                className="be_host_icon"
                                                width="40"
                                                height="40"
                                                viewBox="0 0 40 40"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle cx="20" cy="20" r="20" fill="#F4FCFD" />
                                                <path
                                                    d="M20.3175 21.3608C22.3681 21.3608 24.0305 19.7459 24.0305 17.7539C24.0305 15.7618 22.3681 14.147 20.3175 14.147C18.2669 14.147 16.6045 15.7618 16.6045 17.7539C16.6045 19.7459 18.2669 21.3608 20.3175 21.3608Z"
                                                    fill="#24CCE0"
                                                />
                                                <path
                                                    d="M20.2941 10C14.6246 10 10 14.4708 10 20C10 25.5292 14.6246 30 20.2941 30C25.9637 30 30.5882 25.5076 30.5882 20C30.5882 14.4924 25.9637 10 20.2941 10ZM26.3416 26.3067C25.5857 24.3413 23.9404 22.2462 20.2941 22.2462C16.6701 22.2462 15.0025 24.3413 14.2466 26.3067C12.5124 24.73 11.4007 22.4838 11.4007 20C11.4007 15.2484 15.3805 11.3607 20.2941 11.3607C25.2077 11.3607 29.1875 15.2268 29.1875 20C29.1875 22.4838 28.0759 24.73 26.3416 26.3067Z"
                                                    fill="#24CCE0"
                                                />
                                                <path
                                                    d="M33.9997 9.59961C33.7911 9.59961 33.622 9.76875 33.622 9.97739V12.6218H30.9775C30.7689 12.6218 30.5997 12.791 30.5997 12.9996C30.5997 13.2083 30.7689 13.3774 30.9775 13.3774H33.622V16.0218C33.622 16.2305 33.7911 16.3996 33.9997 16.3996C34.2084 16.3996 34.3775 16.2305 34.3775 16.0218V13.3774H37.022C37.2306 13.3774 37.3997 13.2083 37.3997 12.9996C37.3997 12.791 37.2306 12.6218 37.022 12.6218H34.3775V9.97739C34.3775 9.76875 34.2084 9.59961 33.9997 9.59961Z"
                                                    fill="#24CCE0"
                                                    stroke="#24CCE0"
                                                    stroke-width="0.4"
                                                />
                                            </svg>
                                            <span className="text-dark">
                                                Request to be a host or parent
                                            </span>
                                        </span>
                                        <svg
                                            style={{ marginLeft: '10px', marginRight: '10px' }}
                                            width="10"
                                            height="18"
                                            viewBox="0 0 10 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M0.45666 17.0315C0.25981 17.2284 0.25981 17.5433 0.45666 17.7402C0.645638 17.937 0.968472 17.937 1.15745 17.7402L9.54328 9.35435C9.74013 9.1575 9.74013 8.84254 9.54328 8.64569L1.15745 0.259865C0.968472 0.063015 0.645638 0.063015 0.45666 0.259865C0.25981 0.456715 0.25981 0.771675 0.45666 0.968527L8.48816 9.00002L0.45666 17.0315Z"
                                                fill="#E5E5E5"
                                            />
                                        </svg>
                                    </Link>
                                </div>
                            </React.Fragment>
                        )}
                    </Fragment>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PetDetails;
