import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Modal, ModalBody, Row, Button } from "reactstrap";
import BackIcon from "../../assets/icons/Icon_256w_Back_Black.png";
import Share from "../../assets/icons/Button_256w_Share.png";
import "./publicViewDocument.css";
import Test from "../../assets/test.pdf";
import PDFViewer from "./PDFViewer";
import * as petActions from '../../redux/pet/petActions';
import * as petReducers from '../../redux/pet/petReducers';
import { useDispatch, useSelector } from 'react-redux';
import * as imageActions from '../../redux/image/imageActions';
import * as imageReducers from '../../redux/image/imageReducers';
import NoPetImage from '../../assets/icons/Icon_256w_UnSelectedPet_NoImage.png';
import CryptoJS from "crypto-js";

let PublicViewDocument = (props) => {

  const documentId = useParams().docId;

  const [editModal, setEditModal] = useState(false);
  const editToggle = () => setEditModal(!editModal);
  const [document, setDocument] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [petFilename, setPetFilename] = useState("");
  const [petId, setPetId] = useState();

  const dispatch = useDispatch();
  const scrollRef = React.createRef()

  useEffect(() => {
    setPageNumber(1)
  }, []);

  // dispatch pet details action
  useEffect(() => {
    const decryptpetId = CryptoJS.AES.decrypt(sessionStorage.getItem('petId'), process.env.REACT_APP_CRYPTO_SECRET)
    setPetId(Number(JSON.parse(decryptpetId.toString(CryptoJS.enc.Utf8))));
    petId && dispatch(petActions.getPetDetails(petId));
  }, [petId]);

  // get pet info
  const petInfo = useSelector((state) => {
    return state[petReducers.petFeatureKey];
  });

  const { loading, pet, error, humanAge } = petInfo;
  if (error) console.error(error);

  // get pet profile image
  useEffect(() => {
    if (pet?.pet_images?.length > 0) {
      const fileName = pet?.pet_images?.filter(data => data.type === "PROFILE");
      const exactFileName = fileName[fileName.length - 1]?.filename;
      setPetFilename(fileName[fileName.length - 1]?.filename);
      exactFileName && dispatch(imageActions.getPetImage(exactFileName));
    }
  }, [pet]);

  let imageInfo = useSelector((state) => {
    return state[imageReducers.imageFeatureKey];
  });

  useEffect(async () => {
    if (pet) {
      let findDocument = await pet?.pet_images?.filter((docs) => docs?.id.toString() === documentId)[0]?.filename;
      setDocument(pet?.pet_images?.filter((docs) => docs?.id.toString() === documentId)[0]);
      findDocument && dispatch(imageActions.getDocument(findDocument));
    }
  }, [pet, documentId]);

  let { petImageUrl, documentUrl } = imageInfo;
  function handleScroll(e) {
    e.preventDefault()
    const wrappedElement = scrollRef.current
    if (wrappedElement.scrollHeight - wrappedElement.scrollTop - wrappedElement.clientHeight <= 0) {
      (pageNumber < numPages) && setPageNumber(skip => skip + 1)
    }
  }


  return (
    <React.Fragment>
      {
        loading ?
          <React.Fragment>
            <h2 className="text-center text-muted mt-5">Loading...</h2>
          </React.Fragment> :
          <React.Fragment>
            {
              pet && Object.keys(pet).length > 0 &&
              <React.Fragment>
                <section>
                  <Container fluid className="mt-3">
                    <Row>
                      <Col lg="6" md="6" sm="6" xs="6">
                        <div className="header_pvd">
                          <img src={BackIcon} className="backIcon" alt="" onClick={e => props.history.goBack()} />
                          <img src={petFilename ? petImageUrl : NoPetImage} className="pet_img" alt="" />
                          <h3 className="text_wrapping">{pet?.name}</h3>
                        </div>
                      </Col>
                      <Col lg="6" md="6" sm="6" xs="6">
                        <div className="float-right px-2 share_icon_pvd">
                          {/* <RWebShare
                            data={{
                              text: "",
                              url: Test,
                              title: "",
                            }}
                            onClick={() => console.info("shared successfully!")}
                          >
                            <img src={Share} alt="" />
                          </RWebShare> */}
                          <a href={documentUrl && documentUrl} target="_blank" download={`${document?.src}`}>
                            <img src={Share} onClick={(e) => {
                              editToggle();
                            }} alt="" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <Container fluid className="mt-3">
                    <Row>
                      <Col>
                        {
                          document && Object.keys(document).length > 0 &&
                          <React.Fragment>
                            {
                              document?.src !== "" &&
                              <h6 className="font-weight-bold">{document?.src}</h6>
                            }
                          </React.Fragment>
                        }
                      </Col>
                    </Row>
                  </Container>
                  <Container fluid className="mt-2">
                    <Row>
                      <Col>
                        <div className="all-page-container" ref={scrollRef} onScroll={handleScroll}>
                          <PDFViewer pdf={documentUrl && documentUrl} id="capture" setNumPages={setNumPages} pageNumber={pageNumber} />
                        </div>
                        <center>
                          <div className='contentInCenter'>
                            <Button onClick={() => pageNumber > 1 && setPageNumber(pageNumber => pageNumber - 1)} className='mr-2'> Prev </Button>
                            <span>
                              Page {pageNumber} of {numPages}
                            </span>
                            <Button className='ml-2' onClick={() => pageNumber < numPages && setPageNumber(pageNumber => pageNumber + 1)}> Next </Button>
                          </div>
                        </center>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </React.Fragment>
            }
            {/* <React.Fragment>
              {
                <Modal isOpen={editModal} toggle={editToggle} style={{ position: 'absolute', bottom: '10px', left: '10px', right: '10px' }} >
                  <ModalBody>
                    <Container fluid>
                      <Row>
                        <Col xs='3'>
                          <FacebookShareButton
                            url={"https://www.google.com"}
                            quote={"test quote"}
                            hashtag={"#hashtag"}
                            description={"test Description"}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={40} />
                          </FacebookShareButton>
                        </Col>
                        <Col xs='3'>
                          <WhatsappShareButton
                            url={"https://www.google.com"}
                            quote={"test quote"}
                            hashtag={"#hashtag"}
                            description={"test Description"}
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={40} />
                          </WhatsappShareButton>
                        </Col>
                        <Col xs='3'>
                          <TwitterShareButton
                            url={"https://www.google.com"}
                            quote={"test quote"}
                            hashtag={"#hashtag"}
                            description={"test Description"}
                            className="Demo__some-network__share-button"
                          >
                            <TwitterIcon size={40} />
                          </TwitterShareButton>
                        </Col>
                        <Col xs='3'>
                          <LinkedinShareButton
                            url={"https://www.google.com"}
                            quote={"test quote"}
                            hashtag={"#hashtag"}
                            description={"test Description"}
                            className="Demo__some-network__share-button"
                          >
                            <LinkedinIcon size={40} />
                          </LinkedinShareButton>
                        </Col>
                        <Col xs='3' className="mt-3">
                          <TelegramShareButton
                            url={"https://www.google.com"}
                            quote={"test quote"}
                            hashtag={"#hashtag"}
                            description={"test Description"}
                            className="Demo__some-network__share-button"
                          >
                            <TelegramIcon size={40} />
                          </TelegramShareButton>
                        </Col>
                        <Col xs='3' className="mt-3">
                          <FacebookMessengerShareButton
                            url={"https://www.google.com"}
                            quote={"test quote"}
                            hashtag={"#hashtag"}
                            description={"test Description"}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookMessengerIcon size={40} />
                          </FacebookMessengerShareButton>
                        </Col>
                        <Col xs='3' className="mt-3">
                          <EmailShareButton
                            url={"https://www.google.com"}
                            quote={"test quote"}
                            hashtag={"#hashtag"}
                            description={"test Description"}
                            className="Demo__some-network__share-button"
                          >
                            <EmailIcon size={40} />
                          </EmailShareButton>
                        </Col>
                      </Row>
                    </Container>
                  </ModalBody>
                </Modal>
              }
            </React.Fragment> */}
          </React.Fragment>
      }
    </React.Fragment>
  )
};

export default PublicViewDocument;