import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "./publicViewPerson.css";
import BackIcon from "../../assets/icons/Icon_256w_Back_Black.png";
import exlaimation from "../../assets/icons/Button_256w_Information.png";
import Message from "../../assets/icons/Icon_256w_Chat_Blue.png";
import Gps from "../../assets/icons/Icon_256w_GPS_Highlight.png";
import * as petActions from '../../redux/pet/petActions';
import * as petReducers from '../../redux/pet/petReducers';
import { useDispatch, useSelector } from 'react-redux';
import * as imageActions from '../../redux/image/imageActions';
import * as imageReducers from '../../redux/image/imageReducers';
import { sendMessageTemplate } from "../publicSendMessage/sendMessageTemplate";
import axios from "axios";
import NoPetImage from '../../assets/icons/Icon_256w_UnSelectedPet_NoImage.png';
import NoProfile from "../../assets/icons/Icon_256w_UnSelectedUser_NoImage.png";
import Geocode from "react-geocode";
import CryptoJS from "crypto-js";
import useWindowDimensions from "../../helper";

let PublicViewPerson = (props) => {

  const [userInfo, setUserInfo] = useState({});
  const [mapLink, setMapLink] = useState("");
  const [userImage, setUserImage] = useState('');
  const [petFilename, setPetFilename] = useState("");
  const [userFilename, setUserFilename] = useState("");
  const [petId, setPetId] = useState();
  const [userEmail, setUserEmail] = useState("");
  const { width } = useWindowDimensions();
  const height = window.innerHeight;
  const parentType = useParams().parent;
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
  // Geocode.setLanguage("en");
  // Geocode.setRegion("es");
  // Geocode.enableDebug();
  const dispatch = useDispatch();

  // dispatch pet details action
  useEffect(() => {
    const decryptEmail = CryptoJS.AES.decrypt(sessionStorage.getItem('userEmail'), process.env.REACT_APP_CRYPTO_SECRET)
    const decryptpetId = CryptoJS.AES.decrypt(sessionStorage.getItem('petId'), process.env.REACT_APP_CRYPTO_SECRET)
    setPetId(Number(JSON.parse(decryptpetId.toString(CryptoJS.enc.Utf8))));
    setUserEmail(JSON.parse(decryptEmail.toString(CryptoJS.enc.Utf8)));
    petId && dispatch(petActions.getPetDetails(petId));
  }, [petId]);

  // get pet info
  const petInfo = useSelector((state) => {
    return state[petReducers.petFeatureKey];
  });

  const { loading, pet, error } = petInfo;
  if (error) console.error(error);

  useEffect(() => {
    if (pet) {
      if (pet?.pet_owners?.length > 0) {
        if (parentType === "owner") {
          setUserInfo(pet?.pet_owners?.filter(data => data?.owner_user?.email === userEmail)[0]?.owner_user);
        }
      }

      if (pet?.pet_co_owners?.length > 0) {
        if (parentType === "co-owner") {
          setUserInfo(pet?.pet_co_owners?.filter(data => data?.co_owned_user?.email === userEmail)[0]?.co_owned_user);
        }
      }

      if (pet?.pet_hosts?.length > 0) {
        if (parentType === "host") {
          setUserInfo(pet?.pet_hosts?.filter(data => data?.host_user?.email === userEmail)[0]?.host_user);
        }
      }
    }
  }, [parentType, userEmail, petId, pet]);

  // get pet profile image
  useEffect(() => {
    if (pet?.pet_images?.length > 0) {
      const fileName = pet?.pet_images?.filter(data => data.type === "PROFILE");
      const exactFileName = fileName[fileName.length - 1]?.filename;
      setPetFilename(fileName[fileName.length - 1]?.filename);
      if (exactFileName) {
        dispatch(imageActions.getPetImage(exactFileName));
      }
    }
  }, [parentType, userEmail, petId, pet]);

  // get profile image
  useEffect(() => {
    if (userInfo?.profile_images?.length > 0) {
      const fileName = userInfo?.profile_images[userInfo?.profile_images?.length - 1].filename;
      setUserFilename(fileName);
      if (fileName) {
        dispatch(imageActions.getUserImage(fileName));
      }
    }
  }, [parentType, userEmail, petId, pet]);

  let imageInfo = useSelector((state) => {
    return state[imageReducers.imageFeatureKey];
  });

  let { petImageUrl, userImageUrl } = imageInfo;


  // location

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    const crd = pos.coords;

    // console.log("Your current position is:");
    // console.log(`Latitude : ${crd.latitude}`);
    // console.log(`Longitude: ${crd.longitude}`);
    // console.log(`More or less ${crd.accuracy} meters.`);
    // console.log(`https://www.google.com/maps/search/?api=1&query=${crd.latitude},${crd.longitude}`);
    // setMapLink(`https://www.google.com/maps/search/?api=1&query=${crd.latitude},${crd.longitude}`);

    Geocode.fromLatLng(crd.latitude, crd.longitude).then(
      (response) => {
        const address = response.results[1].formatted_address;
        setMapLink(`<a href="https://www.google.com/maps/search/?api=1&query=${crd.latitude},${crd.longitude}">${address}</a>`);
      },
      (error) => {
        console.error(error);
      }
    );


  }

  function errors(err) {
    setMapLink("unknown");
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  const sendGpsLocation = () => {
    if (navigator.geolocation) {
      // navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      //   if (result.state === "granted") {
      //     // console.log(result.state);
      //     navigator.geolocation.getCurrentPosition(success);
      //   } else if (result.state === "prompt") {
      //     navigator.geolocation.getCurrentPosition(success, errors, options);
      //   } else if (result.state === "denied") {
      //   }
      //   result.onchange = function () {
      //     // console.log(result.state);
      //   };
      // })
      navigator.geolocation.getCurrentPosition(success, errors, options);
    } else {
      console.log("Something went wrong in geoLocation");
    }

  };



  useEffect(() => {
    sendGpsLocation()
  }, []);

  // send email
  const sendGpsLocationEmail = async (e) => {
    let sendEmailData = await {
      recipients: [userInfo?.email, 'email_log@jelliepets.com'],
      subject: `Someone has sent you a message`,
      body: sendMessageTemplate('', pet?.name, mapLink)
    };
    let dataUrl = process.env.REACT_APP_EMAIL_BASE_URL;
    await axios.post(dataUrl, sendEmailData);
    // setAlert(true);
    // setAlertMessage("Email sent");
    // setAlertColor("success");
    await props.history.goBack()
  };




  return (
    <React.Fragment>
      <section style={{ height: `${height}px`, width: `100%`, position: "relative" }}>
        {
          pet && Object.keys(pet).length > 0 &&
          <React.Fragment>
            <Container fluid className="pt-3">
              <Row>
                <Col>
                  <div className="header_pvp">
                    <img src={BackIcon} className="backIcon" alt="" onClick={e => props.history.goBack()} />
                    <img src={petFilename ? petImageUrl : NoPetImage} className="pet_img" alt="" />
                    <h3 className="text_wrapping">{pet?.name}</h3>
                  </div>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        }
        {
          loading ?
            <h3 className="text-center text-muted mt-5">Loading...</h3> :
            <React.Fragment>
              {
                userInfo && Object.keys(userInfo).length > 0 &&
                <React.Fragment>
                  <Container fluid className="mt-4">
                    <Row>
                      <Col lg="2" md="3" sm="5" xs="5">
                        <img src={userFilename ? userImageUrl : NoProfile} style={{ width: "130px", height: "130px" }} className="rounded-circle" alt="" />
                      </Col>
                      <Col lg="10" md="9" sm="7" xs="7" className="text-left mt-2" style={{ marginLeft: "0px", paddingLeft: "0px" }}>
                        <h6 className="parent__name">
                          {/* {userInfo?.first_name} {userInfo?.last_name} */}
                          {
                            userInfo?.first_name === null || userInfo?.first_name === "null" || userInfo?.first_name === "" ?
                              `${userInfo?.email.split('@')[0]}`
                              :
                              `${userInfo?.first_name}`
                          }
                        </h6>
                        <p className="parent_host">
                          {
                            parentType === 'host' ?
                              `Host` : `Parent`
                          }
                        </p>
                      </Col>
                    </Row>
                  </Container>
                  <Container fluid className="mt-2">
                    <Row>
                      <Col>
                        <div className="p-2" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "60px" }}>
                          {
                            parentType === 'host' ?
                              <span className="para">Hosts have limited control over pet</span> :
                              <span className="para">Parents have full control over pet</span>
                          }
                          <a href="https://jelliepets.com/help" target="_blank"><img src={exlaimation} className="exclIcon_pvp" alt="" /></a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  {
                    pet && Object.keys(pet).length > 0 &&
                    <React.Fragment>
                      <div style={{ position: "absolute", bottom: "86px", left: "0", right: "0" }}>
                        <div className="person_link">
                          <Row tag={Link} to={`/person/message/${parentType}`} className="d-flex align-items-center">
                            <Col className="bottom-link" sm="2" xs="3" >
                              <img src={Message} className="ml-3 msg_pvp" alt="" />
                            </Col>
                            <Col sm="9" xs="8">
                              <p className="mt-3 text-dark" style={{ fontWeight: "600", fontSize: "16px" }}>Message this person</p>
                            </Col>
                            <Col sm="1" xs="1">
                              <span className="float-right mr-2">
                                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0.45666 17.0315C0.25981 17.2284 0.25981 17.5433 0.45666 17.7402C0.645638 17.937 0.968472 17.937 1.15745 17.7402L9.54328 9.35435C9.74013 9.1575 9.74013 8.84254 9.54328 8.64569L1.15745 0.259865C0.968472 0.063015 0.645638 0.063015 0.45666 0.259865C0.25981 0.456715 0.25981 0.771675 0.45666 0.968527L8.48816 9.00002L0.45666 17.0315Z" fill="#E5E5E5" />
                                </svg>
                              </span>
                            </Col>
                          </Row>
                        </div>
                        <div className="person_link" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}
                          // onClick={sendGpsLocation}
                          onClick={sendGpsLocationEmail}
                        >
                          <Row className="d-flex align-items-center">
                            <Col className="bottom-link" sm="2" xs="3">
                              <img src={Gps} className="ml-2 gps_pvp" alt="" />
                            </Col>
                            <Col sm="9" xs="8">
                              <p className="mt-3" style={{ fontWeight: "600", fontSize: "16px" }}>Send current GPS location</p>
                            </Col>
                            <Col sm="1" xs="1">
                              <span className="float-right mr-2">
                                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0.45666 17.0315C0.25981 17.2284 0.25981 17.5433 0.45666 17.7402C0.645638 17.937 0.968472 17.937 1.15745 17.7402L9.54328 9.35435C9.74013 9.1575 9.74013 8.84254 9.54328 8.64569L1.15745 0.259865C0.968472 0.063015 0.645638 0.063015 0.45666 0.259865C0.25981 0.456715 0.25981 0.771675 0.45666 0.968527L8.48816 9.00002L0.45666 17.0315Z" fill="#E5E5E5" />
                                </svg>
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </React.Fragment>
        }
      </section>
    </React.Fragment>
  )
};

export default PublicViewPerson;