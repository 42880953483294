import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import BackIcon from "../../assets/icons/Icon_256w_Back_Black.png";
import * as imageReducers from '../../redux/image/imageReducers';
import * as petReducers from '../../redux/pet/petReducers';
import NoPetImage from '../../assets/icons/Icon_256w_UnSelectedPet_NoImage.png';

const ProfileImage = (props) => {

  let imageInfo = useSelector((state) => {
    return state[imageReducers.imageFeatureKey];
  });

  let { petImageUrl } = imageInfo;

  const petInfo = useSelector((state) => {
    return state[petReducers.petFeatureKey];
  });

  const { pet, error } = petInfo;
  if (error) console.error(error);

  return (
    <React.Fragment>
      <Container fluid className="mt-3 mb-4">
        <Row>
          <Col>
            <div className="home_header">
              <img src={BackIcon} className="back mt-1" alt="" onClick={e => props.history.goBack()} />
              <h3 className="mr-4">{pet && pet?.name}</h3>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-5">
        <Row>
          <Col className="text-center">
            <img src={petImageUrl ? petImageUrl : NoPetImage} style={{ height: "350px" }} alt="" />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
};

export default ProfileImage;