import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { Col, Container, Row } from "reactstrap";
import BackIcon from "../../assets/icons/Icon_256w_Back_Black.png";
import "./viewProduct.css";
import { Link, useParams } from "react-router-dom";
import * as petActions from '../../redux/pet/petActions';
import * as petReducers from '../../redux/pet/petReducers';
import { useDispatch, useSelector } from 'react-redux';
import * as imageActions from '../../redux/image/imageActions';
import * as imageReducers from '../../redux/image/imageReducers';
import CryptoJS from "crypto-js";
import DEFAULT_PRODUCT_IMG from '../../assets/icons/Icon_256w_Product_NoImage.png';

const ViewProduct = (props) => {

  const [petId, setPetId] = useState();
  const [productFilename, setProductFilename] = useState("");
  const productId = useParams().productId;
  const dispatch = useDispatch();

  const [productInfo, setProductInfo] = useState({});

  // dispatch pet details action
  useEffect(() => {
    const decryptpetId = CryptoJS.AES.decrypt(sessionStorage.getItem('petId'), process.env.REACT_APP_CRYPTO_SECRET)
    setPetId(Number(JSON.parse(decryptpetId.toString(CryptoJS.enc.Utf8))));
    petId && dispatch(petActions.getPetDetails(petId));
  }, [petId]);
  // get pet info
  const petInfo = useSelector((state) => {
    return state[petReducers.petFeatureKey];
  });

  const { loading, pet, error } = petInfo;
  if (error) console.error(error);

  useEffect(() => {
    if (pet) {
      setProductInfo(pet?.routine_tasks?.filter((data) => data?.pet_product?.id === Number(productId))[0]?.pet_product);
    }
  }, [pet, petId, productId]);

  // get product image
  useEffect(async () => {
    if (productInfo?.pet_images?.length > 0) {
      const fileName = await productInfo?.pet_images[productInfo?.pet_images?.length - 1].filename;
      setProductFilename(fileName);
      if (fileName) {
        dispatch(imageActions.getProductImage(fileName));
      }
    } else {
      dispatch(imageActions.clearReduxState());
    }
  }, [productInfo]);

  const imageInfo = useSelector((state) => {
    return state[imageReducers.imageFeatureKey];
  });

  const { productImageUrl } = imageInfo;

  return (
    <React.Fragment>
      {
        loading ?
          <h2 className="mt-5 text-center text-muted">Loading...</h2> :
          <React.Fragment>
            {
              productInfo && Object.keys(productInfo).length > 0 &&
              <React.Fragment>
                <Container fluid className="mt-3 mb-4">
                  <Row>
                    <Col>
                      <div className="home_header">
                        <img src={BackIcon} className="back mt-1" alt="" onClick={e => props.history.goBack()} />
                      </div>
                    </Col>
                  </Row>
                </Container>
                <Container fluid className="view_product mt-3 mb-3">
                  <div>
                    <Row>
                      <Col>
                        <h6>{productInfo?.name}</h6>
                        {
                          productInfo?.serving_calories !== null || productInfo.serving_calories !== "" || productInfo?.serving_unit !== null || productInfo.serving_unit !== "" ?
                            <span>
                              +{productInfo.serving_calories} cal <span>per {productInfo.serving_unit}</span>
                            </span> : null
                        }
                      </Col>
                    </Row>
                    <div className="product_img">
                      <img src={productFilename ? productImageUrl : DEFAULT_PRODUCT_IMG} alt="" srcset="" />
                    </div>
                    <Collapsible
                      open={true}
                      trigger={[
                        <p className="collapse_name">Full details</p>,
                        <svg
                          className="down_arrow"
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.41 1.26284e-07L6 4.58L10.59 1.68141e-08L12 1.41L6 7.40999L1.68141e-08 1.41L1.41 1.26284e-07Z"
                            fill="#616161"
                          />
                        </svg>,
                      ]}
                    >
                      <div className="collapse_inner_content">
                        <div className="profile_collapse_row">
                          <span className="profile_collapse_row_content">
                            <div className="collapse_inner_content">

                              {productInfo?.manufacturer_details?.name ?
                                <div className="profile_collapse_row">
                                  <span className="profile_collapse_row_title">
                                    Manufacturer:
                                  </span>
                                  <span className="profile_collapse_row_content">
                                    {productInfo.manufacturer_details?.name}
                                  </span>
                                </div> : null}

                              {productInfo?.brand ?
                                <div className="profile_collapse_row">
                                  <span className="profile_collapse_row_title">Brand:</span>
                                  <span className="profile_collapse_row_content">
                                    {productInfo.brand}
                                  </span>
                                </div> : null}

                              {productInfo?.type ?
                                <div className="profile_collapse_row">
                                  <span className="profile_collapse_row_title">Type:</span>
                                  <span className="profile_collapse_row_content">
                                    {productInfo.type}
                                  </span>
                                </div> : null}

                              {productInfo?.specie ?
                                <div className="profile_collapse_row">
                                  <span className="profile_collapse_row_title">
                                    Specie:
                                  </span>
                                  <span className="profile_collapse_row_content">{productInfo.specie}</span>
                                </div> : null}

                              {productInfo?.barcode ?
                                <div className="profile_collapse_row">
                                  <span className="profile_collapse_row_title">
                                    Barcode:
                                  </span>
                                  <span className="profile_collapse_row_content">
                                    {productInfo.barcode}
                                  </span>
                                </div> : null}

                              {productInfo?.nutrient_protein ?
                                <div className="profile_collapse_row">
                                  <span className="profile_collapse_row_title">
                                    Protein (min):
                                  </span>
                                  <span className="profile_collapse_row_content">
                                    {productInfo.nutrient_protein}
                                  </span>
                                </div> : null}

                              {productInfo?.nutrient_fat ?
                                <div className="profile_collapse_row">
                                  <span className="profile_collapse_row_title">
                                    Fat (min):
                                  </span>
                                  <span className="profile_collapse_row_content">
                                    {productInfo.nutrient_fat}
                                  </span>
                                </div> : null}

                              {productInfo?.nutrient_fiber ?
                                <div className="profile_collapse_row">
                                  <span className="profile_collapse_row_title">
                                    Fiber (max):
                                  </span>
                                  <span className="profile_collapse_row_content">
                                    {productInfo.nutrient_fiber}
                                  </span>
                                </div> : null}

                              {productInfo?.nutrient_ash ?
                                <div className="profile_collapse_row">
                                  <span className="profile_collapse_row_title">
                                    Ash (est):
                                  </span>
                                  <span className="profile_collapse_row_content">
                                    {productInfo.nutrient_ash}
                                  </span>
                                </div> : null}

                              {productInfo?.nutrient_moisture ?
                                <div className="profile_collapse_row">
                                  <span className="profile_collapse_row_title">
                                    Moister (max):
                                  </span>
                                  <span className="profile_collapse_row_content">
                                    {productInfo.nutrient_moisture}
                                  </span>
                                </div> : null}

                              {productInfo?.ingredients ?
                                <div className="profile_collapse_row desc">
                                  <span className="profile_collapse_row_title">
                                    Ingredients:
                                  </span>
                                  <span className="profile_collapse_row_content">
                                    {productInfo.ingredients}
                                  </span>
                                </div> : null}

                              {productInfo?.product_tags ?
                                <div className="profile_collapse_row desc">
                                  <span className="profile_collapse_row_title">Tags:</span>
                                  <span className="profile_collapse_row_content">
                                    {productInfo.product_tags}
                                  </span>
                                </div> : null}
                            </div>
                          </span>
                        </div>
                      </div>
                    </Collapsible>
                  </div>
                </Container>
              </React.Fragment>
            }
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ViewProduct;
