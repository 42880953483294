import React from "react";
import "./App.css"
import PetProfile from "./view/pet-profile/PetProfile";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PetRequestParent from "./view/pet-request.parent/PetRequestParent";
import PetDigitalId from "./view/petDigitalId/PetDigitalId";
import PublicViewDocument from "./view/publicViewDocument/PublicViewDocument";
import PublicViewPerson from "./view/publicViewPerson/PublicViewPerson";
import PublicSendMessage from "./view/publicSendMessage/PublicSendMessage";
import ViewProduct from "./view/viewProduct/ViewProduct";
import ProfileImage from "./view/pet-profile/ProfileImage";
import Version from "./view/Version";
import PetDetails from "./view/pet-profile/PetDetails";

window.location.href === "https://www.jellieid.com/" || window.location.href === "https://jellieid.com/" && window.location.replace('https://jelliepets.com/JellieID')
window.location.href === "https://www.jelliepet.com/" || window.location.href === "https://jelliepet.com/" && window.location.replace('https://jelliepets.com')

let App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/:pet_id" component={PetProfile} />
        <Route exact path="/pet/details" component={PetDetails} />
        <Route exact path="/pet/image" component={ProfileImage} />
        <Route exact path="/petrequest/parent" component={PetRequestParent} />
        <Route exact path="/pet/qr" component={PetDigitalId} />
        <Route exact path="/document/:docId" component={PublicViewDocument} />
        <Route exact path="/person/:parent" component={PublicViewPerson} />
        <Route exact path="/person/message/:parent" component={PublicSendMessage} />
        <Route exact path="/product/:productId" component={ViewProduct} />
        <Route exact path="/build/version" component={Version} />
      </Switch>
    </Router>
  )
};

export default App;