import React from 'react';

const Version = () => {
    return (
        <React.Fragment>
            <h1>Date: 25 June 2022</h1>
        </React.Fragment>
    )
};

export default Version;