import React, { useEffect, useState } from "react";
import "./publicSendMessage.css";
import BackIcon from "../../assets/icons/Icon_256w_Back_Black.png";
import { Alert, Col, Container, Row } from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Message from "../../assets/untitled folder/message.png";
import { sendMessageTemplate } from "./sendMessageTemplate";
import axios from "axios";
import * as petActions from '../../redux/pet/petActions';
import * as petReducers from '../../redux/pet/petReducers';
import { useDispatch, useSelector } from 'react-redux';
import * as imageActions from '../../redux/image/imageActions';
import * as imageReducers from '../../redux/image/imageReducers';
import NoPetImage from '../../assets/icons/Icon_256w_UnSelectedPet_NoImage.png';
import Geocode from "react-geocode";
import CryptoJS from "crypto-js";
import useWindowDimensions from "../../helper";

let PublicSendMessage = (props) => {

  let history = useHistory();

  const [enableGps, setEnableGps] = useState(true);
  const [verified, setVerified] = useState(false);
  const [mapLink, setMapLink] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [message, setMessage] = useState("");
  // const [userEmail, setUserEmail] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("")
  const [alertColor, setAlertColor] = useState("")
  const [petFilename, setPetFilename] = useState("");
  const [petId, setPetId] = useState();
  const [userEmail, setUserEmail] = useState("");
  const { width } = useWindowDimensions();
  const height = window.innerHeight;
  // const petId = useParams().petId;
  // const userEmail = useParams().userEmail;
  const parentType = useParams().parent;

  const dispatch = useDispatch();
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

  useEffect(() => {
    sendGpsLocation();
  }, []);

  // dispatch pet details action
  useEffect(() => {
    const decryptEmail = CryptoJS.AES.decrypt(sessionStorage.getItem('userEmail'), process.env.REACT_APP_CRYPTO_SECRET)
    const decryptpetId = CryptoJS.AES.decrypt(sessionStorage.getItem('petId'), process.env.REACT_APP_CRYPTO_SECRET)
    setPetId(Number(JSON.parse(decryptpetId.toString(CryptoJS.enc.Utf8))));
    setUserEmail(JSON.parse(decryptEmail.toString(CryptoJS.enc.Utf8)));
    petId && dispatch(petActions.getPetDetails(petId));
  }, [petId]);

  // get pet info
  const petInfo = useSelector((state) => {
    return state[petReducers.petFeatureKey];
  });

  const { loading, pet, error } = petInfo;
  if (error) console.error(error);

  useEffect(() => {
    if (pet) {
      if (pet?.pet_owners?.length > 0) {
        if (parentType === "owner") {
          setUserInfo(pet?.pet_owners?.filter(data => data?.owner_user?.email === userEmail)[0]?.owner_user);
        }
      }

      if (pet?.pet_co_owners?.length > 0) {
        if (parentType === "co-owner") {
          setUserInfo(pet?.pet_co_owners?.filter(data => data?.co_owned_user?.email === userEmail)[0]?.co_owned_user);
        }
      }

      if (pet?.pet_hosts?.length > 0) {
        if (parentType === "host") {
          setUserInfo(pet?.pet_hosts?.filter(data => data?.host_user?.email === userEmail)[0]?.host_user);
        }
      }
    }
  }, [parentType, userEmail, petId, pet]);

  // get pet profile image
  useEffect(() => {
    if (pet?.pet_images?.length > 0) {
      const fileName = pet?.pet_images?.filter(data => data.type === "PROFILE");
      const exactFileName = fileName[fileName.length - 1]?.filename;
      setPetFilename(fileName[fileName.length - 1]?.filename);
      if (exactFileName) {
        dispatch(imageActions.getPetImage(exactFileName));
      }
    }
  }, [parentType, userEmail, petId, pet]);

  useEffect(() => {
    if (enableGps === true) {
      sendGpsLocation();
    } else if (enableGps === false) {
      setMapLink("unknown");
    }
  }, [enableGps]);

  let imageInfo = useSelector((state) => {
    return state[imageReducers.imageFeatureKey];
  });

  let { petImageUrl } = imageInfo;


  function onChange(value) {
    setVerified(true);
  };

  // location
  const changeEnableGps = () => {
    setEnableGps(!enableGps);
  };

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    const crd = pos.coords;

    Geocode.fromLatLng(crd.latitude, crd.longitude).then(
      (response) => {
        const address = response.results[1].formatted_address;
        setMapLink(`<a href="https://www.google.com/maps/search/?api=1&query=${crd.latitude},${crd.longitude}">${address}</a>`);
      },
      (error) => {
        console.error(error);
      }
    );


  }

  function errors(err) {
    setMapLink("unknown");
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  const sendGpsLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, errors, options);
    } else {
      console.log("Something went wrong in geoLocation");
    }

  };

  const sendEmail = async () => {
    let sendEmailData = {
      recipients: [userInfo?.email, 'email_log@jelliepets.com'],
      subject: `Someone has sent you a message`,
      body: sendMessageTemplate(message, pet?.name, mapLink)
    };
    let dataUrl = process.env.REACT_APP_EMAIL_BASE_URL;
    await axios.post(dataUrl, sendEmailData);
    // setAlert(true);
    // setAlertMessage("Email sent");
    // setAlertColor("success");
    // props.history.goBack()
    history.push(`/${petId}`);
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 3050)
    }
  }, [alert]);

  return (
    <React.Fragment>
      <div className="alert-list">
        {alert &&
          <Alert color={alertColor}>{message}</Alert>
        }
      </div>
      {
        loading ?
          <h2 className="text-center text-muted mt-5">Loading...</h2> :
          <React.Fragment>
            <section style={{ height: `${height}px`, width: `${width}px`, position: "relative" }}>
              {
                pet && Object.keys(pet).length > 0 &&
                <React.Fragment>
                  <Container fluid className="pt-3">
                    <Row>
                      <Col>
                        <div className="header_psm">
                          <img onClick={e => props.history.goBack()} src={BackIcon} className="backIcon" alt="" />
                          <img src={petFilename ? petImageUrl : NoPetImage} className="pet_img" alt="" />
                          <h3 className="text_wrapping">{pet?.name}</h3>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </React.Fragment>
              }
              {
                userInfo && Object.keys(userInfo).length > 0 &&
                <React.Fragment>
                  <Container fluid className="mt-4">
                    <Row>
                      <Col>
                        {/* {userInfo?.first_name} {userInfo?.last_name} */}
                        <span className="px-2" style={{ fontWeight: "600" }}>Your message to: {
                          userInfo?.first_name === null || userInfo?.first_name === "null" || userInfo?.first_name === "" ?
                            `${userInfo?.email.split('@')[0]}`
                            :
                            `${userInfo?.first_name}`
                        } <span className="text-muted" style={{ fontWeight: "400", color: "#616161" }}>({parentType === "host" ? `Host` : 'Parent'})</span></span>
                      </Col>
                    </Row>
                  </Container>
                  <Container fluid>
                    <Row>
                      <Col>
                        <textarea className="form-control" maxLength="125" rows="3" placeholder="...I love your pet..." style={{ border: "1px solid #E5E5E5", borderRadius: "10px", resize: "none", fontSize: "16px", color: "#295E65", fontWeight: "500" }} onChange={e => setMessage(e.target.value)} />
                      </Col>
                    </Row>
                  </Container>
                  <Container fluid>
                    <Row>
                      <Col>
                        <div className="mt-2 px-2" style={{ border: "1px solid #E5E5E5", height: "42px", borderRadius: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <span style={{ fontFamily: "Helvetica", fontSize: "14px" }}>Include GPS location</span>
                          <div className="round pr-3">
                            <input type="checkbox" checked={enableGps} onChange={changeEnableGps} id="checkbox" />
                            <label htmlFor="checkbox"></label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <Container fluid className="mt-2">
                    <Row>
                      <Col>
                        <div className="recaptcha-wrapper">
                          <ReCAPTCHA className="g-recaptcha"
                            sitekey={`${process.env.REACT_APP_RECAPTCH_SITE_KEY}`}
                            onChange={onChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <Container fluid className="mt-2">
                    <Row>
                      <Col>
                        <span className="para p-2">An email will be sent to this person</span>
                      </Col>
                    </Row>
                  </Container>
                  {
                    verified && message.length > 3 &&
                    <React.Fragment>
                      <Container fluid style={{ position: 'absolute', bottom: "86px" }}>
                        <Row>
                          <Col style={{ padding: "0px" }}>
                            <div className="box">
                              <Row className="box_row" style={{ width: `${width - 30}px` }} onClick={sendEmail}>
                                {/* <Col md="2" sm="3" xs="3" className="text-center bottom-link_msg">
                                  <img src={Message} alt="" />
                                </Col> */}
                                <Col md="12" sm="12" xs="12" className="text-center"><span className="box_text">Send message</span></Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </section>
          </React.Fragment>
      }
    </React.Fragment>
  )
};

export default PublicSendMessage;