import React, { useEffect, useState } from 'react';
import './petProfile.css';
import {
  useHistory,
} from 'react-router-dom';
import * as petActions from '../../redux/pet/petActions';
import * as petReducers from '../../redux/pet/petReducers';
import { useDispatch, useSelector } from 'react-redux';
import Geocode from "react-geocode";

let PetProfile = (props) => {
  const { match, history: historyProp } = props;
  const history = useHistory();
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      window.location.origin === 'https://www.jellieid.com' || window.location.origin === 'https://jellieid.com' || window.location.origin === 'https://www.jelliepet.com' || window.location.origin === 'https://jelliepet.com' ||
      window.location.search.includes('redirect')
    ) {
      window.location.replace(
        `https://profile.jellie.io/${match.params.pet_id}`
      );
    }
    else
      if (match.params.pet_id) {
        if (!match.params.pet_id.includes('-')) {
          dispatch(petActions.getPetDetails(match.params.pet_id));
        } else {
          if (!navigator.geolocation) {
            console.log('Geolocation is not supported by your browser');
            dispatch(petActions.getPetDetailsByAccessories(match.params.pet_id));
          } else {
            navigator.geolocation.getCurrentPosition((position) => {
              // get address using geolocation API
              Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                (response) => {
                  const address = response.results[1].formatted_address;
                  dispatch(petActions.getPetDetailsByAccessories(match.params.pet_id, position.coords.latitude, position.coords.longitude, address));
                },
                (error) => {
                  console.error(error);
                }
              );
            }, () => {
              dispatch(petActions.getPetDetailsByAccessories(match.params.pet_id));
              console.log('Unable to retrieve your location');
            });
          }
        }
      }
  }, []);

  // get pet info
  const petInfo = useSelector((state) => {
    return state[petReducers.petFeatureKey];
  });

  const { loading, pet, error, humanAge } = petInfo;
  if (error) console.error(error);

  useEffect(() => {
    if (pet) {
      if (pet.id) {
        sessionStorage.setItem('id', (pet.id));
        history.push('/pet/details');
      }
    }
    if (pet === null) {
      window.location.replace('https://jelliepets.com/invalidId');
    }
  }, [pet]);

  return loading && (
    <h2 className="mt-5 text-center text-muted">Loading...</h2>
  )
};

export default PetProfile;
