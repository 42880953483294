import axios from "axios";

export const GET_PET_IMAGE_REQUEST = 'GET_PET_IMAGE_REQUEST';
export const GET_PET_IMAGE_SUCCESS = 'GET_PET_IMAGE_SUCCESS';
export const GET_PET_IMAGE_FAILURE = 'GET_PET_IMAGE_FAILURE';

export const GET_USER_IMAGE_REQUEST = 'GET_USER_IMAGE_REQUEST';
export const GET_USER_IMAGE_SUCCESS = 'GET_USER_IMAGE_SUCCESS';
export const GET_USER_IMAGE_FAILURE = 'GET_USER_IMAGE_FAILURE';

export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE';

export const GET_PRODUCT_IMAGE_REQUEST = 'GET_PRODUCT_IMAGE_REQUEST';
export const GET_PRODUCT_IMAGE_SUCCESS = 'GET_PRODUCT_IMAGE_SUCCESS';
export const GET_PRODUCT_IMAGE_FAILURE = 'GET_PRODUCT_IMAGE_FAILURE';

export const CLEAR_REDUX_STATE = 'CLEAR_REDUX_STATE';

export const getPetImage = (filename) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PET_IMAGE_REQUEST });
            let dataUrl = process.env.REACT_APP_IMAGE_BASE_URL;
            let response = await axios.post(dataUrl, { files: [filename] });
            dispatch({ type: GET_PET_IMAGE_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PET_IMAGE_FAILURE, payload: error });
        }
    }
};

export const getUserImage = (filename) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_USER_IMAGE_REQUEST });
            let dataUrl = process.env.REACT_APP_IMAGE_BASE_URL;
            let response = await axios.post(dataUrl, { files: [filename] });
            dispatch({ type: GET_USER_IMAGE_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_USER_IMAGE_FAILURE, payload: error });
        }
    }
};

export const getDocument = (filename) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_DOCUMENT_REQUEST });
            let dataUrl = process.env.REACT_APP_IMAGE_BASE_URL;
            let response = await axios.post(dataUrl, { files: [filename] });
            dispatch({ type: GET_DOCUMENT_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_DOCUMENT_FAILURE, payload: error });
        }
    }
};

export const getProductImage = (filename) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PRODUCT_IMAGE_REQUEST });
            let dataUrl = process.env.REACT_APP_IMAGE_BASE_URL;
            let response = await axios.post(dataUrl, { files: [filename] });
            dispatch({ type: GET_PRODUCT_IMAGE_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PRODUCT_IMAGE_FAILURE, payload: error });
        }
    }
};

export const clearReduxState = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: CLEAR_REDUX_STATE });
        } catch (error) {
            console.error(error);
        }
    }
};