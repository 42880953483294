import * as petActions from './petActions';

export const petFeatureKey = 'petInfo';

let initialState = {
    loading: false,
    errMessage: "",
    pet: {},
    humanAge: parseInt(''),
    resStatus: false
};

export const reducer = (state = initialState, action) => {
    let { type, payload } = action;
    switch (type) {
        case petActions.GET_PET_REQUEST:
            return {
                ...state,
                loading: true
            };

        case petActions.GET_PET_SUCCESS:
            return {
                ...state,
                loading: false,
                pet: payload.pet_details
            };

        case petActions.GET_PET_FAILURE:
            return {
                ...state,
                loading: false,
                errMessage: payload
            };

        case petActions.GET_PET_BY_ACCESSORY_REQUEST:
            return {
                ...state,
                loading: true
            };

        case petActions.GET_PET_BY_ACCESSORY_SUCCESS:
            return {
                ...state,
                loading: false,
                pet: payload.pet_details
            };

        case petActions.GET_PET_BY_ACCESSORY_FAILURE:
            return {
                ...state,
                loading: false,
                errMessage: payload
            };

        // case petActions.GET_PET_BY_QR_ID_REQUEST:
        //     return {
        //         ...state,
        //         loading: true
        //     };

        // case petActions.GET_PET_BY_QR_ID_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         pet: payload.pet_details
        //     };

        // case petActions.GET_PET_BY_QR_ID_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         errMessage: payload
        //     };

        case petActions.GET_HUMAN_AGE_REQUEST:
            return {
                ...state,
                loading: true
            };

        case petActions.GET_HUMAN_AGE_SUCCESS:
            return {
                ...state,
                loading: false,
                humanAge: payload.equivalent_human_age
            };

        case petActions.GET_HUMAN_AGE_FAILURE:
            return {
                ...state,
                loading: false,
                errMessage: payload
            };

        case petActions.POST_PET_PARENT_REQUEST:
            return {
                ...state,
                loading: true
            };

        case petActions.POST_PET_PARENT_SUCCESS:
            return {
                ...state,
                loading: false,
                resStatus: true
            };

        case petActions.POST_PET_PARENT_FAILURE:
            return {
                ...state,
                loading: false,
                resStatus: false
            };

        case petActions.CLEAR_RES_STATUS:
            return {
                ...state,
                resStatus: false
            }

        case petActions.PET_DETAILS_APP_SUCCESS:
            payload.petId && sessionStorage.setItem('petId', payload.petId);
            payload.userEmail && sessionStorage.setItem('userEmail', payload.userEmail);
            return {
                ...state,
            }

        case petActions.PET_DETAILS_APP_FAILURE:
            return {
                ...state,
                errMessage: payload
            }

        default: return state;
    }
};