import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Alert, Col, Container, Input, ListGroup, ListGroupItem, Row } from "reactstrap";
import BackIcon from "../../assets/icons/Icon_256w_Back_Black.png";
import exlaimation from "../../assets/icons/Button_256w_Information.png";
import "./petRequestParent.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { requestHostEmailTemplate, requestParentEmailTemplate } from './requestEmailTemplate';
import * as petActions from '../../redux/pet/petActions';
import * as petReducers from '../../redux/pet/petReducers';
import { useDispatch, useSelector } from 'react-redux';
import * as imageActions from '../../redux/image/imageActions';
import * as imageReducers from '../../redux/image/imageReducers';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import NoPetImage from '../../assets/icons/Icon_256w_UnSelectedPet_NoImage.png';
import CryptoJS from "crypto-js";
import useWindowDimensions from "../../helper";

let PetRequestParent = (props) => {

  let history = useHistory();
  const dispatch = useDispatch();

  let [petRequest, setPetRequest] = useState("Parent");
  const [petProfile, setPetProfile] = useState({});
  const [parentEmail, setParentEmail] = useState([]);
  const [name, setName] = useState("");
  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState("");
  let [startDate, setStartDate] = useState(moment("", "MM/DD/YYYY").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment('', "MM/DD/YYYY").format("YYYY-MM-DD"));
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [petFilename, setPetFilename] = useState("");
  const [petId, setPetId] = useState();
  const resStatus = useSelector(state => state.petInfo.resStatus)
  const previousProps = useRef({ resStatus });
  const { width } = useWindowDimensions();
  const height = window.innerHeight;


  // dispatch pet details action
  useEffect(() => {
    const decryptpetId = CryptoJS.AES.decrypt(sessionStorage.getItem('petId'), process.env.REACT_APP_CRYPTO_SECRET)
    setPetId(Number(JSON.parse(decryptpetId.toString(CryptoJS.enc.Utf8))));
    petId && dispatch(petActions.getPetDetails(petId));
  }, [petId]);

  // get pet info
  const petInfo = useSelector((state) => {
    return state[petReducers.petFeatureKey];
  });

  const { loading, pet, error, humanAge } = petInfo;
  if (error) console.error(error);


  useEffect(() => {
    if (pet) {
      let petOwners = pet?.pet_owners?.filter(data => data?.accepted === true)?.map(owner => owner?.owner_user?.email);
      let petCoOwner = pet?.pet_co_owners?.filter(data => data?.accepted === true)?.map(owner => owner?.co_owned_user?.email);
      let petHost = pet?.pet_hosts?.filter(data => data?.accepted === true)?.map(owner => owner?.host_user?.email);
      setParentEmail(petOwners?.concat(petCoOwner, petHost));

      // get pet profile image
      if (pet?.pet_images?.length > 0) {
        const fileName = pet?.pet_images?.filter(data => data.type === "PROFILE");
        const exactFileName = fileName[fileName.length - 1]?.filename;
        setPetFilename(fileName[fileName.length - 1]?.filename);
        exactFileName && dispatch(imageActions.getPetImage(exactFileName));
      }

    }

  }, [pet]);

  let imageInfo = useSelector((state) => {
    return state[imageReducers.imageFeatureKey];
  });

  let { petImageUrl } = imageInfo;

  const endAfterStart = (start, end) => {
    let startDate = new Date(start);
    let endDate = new Date(end);
    return endDate.getTime() > startDate.getTime();
  }

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 3050)
    }
  }, [alert]);

  useEffect(() => {
    if (name.length <= 2) {
      setValid(false);
    } else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [email, name]);

  const emails = ['email_log@jelliepets.com', ...parentEmail,];



  useEffect(async () => {

    if (previousProps.resStatus !== resStatus) {
      if (resStatus) {
        let sendEmailData = {
          recipients: emails.length > 0 && emails,
          subject: petRequest === "Host" ? `Someone has requested to become a HOST of ${pet?.name}` : `Someone has requested to become a PARENT of ${pet?.name}`,
          body: petRequest === "Host" ? requestHostEmailTemplate() : requestParentEmailTemplate()
        };
        let dataUrl = process.env.REACT_APP_EMAIL_BASE_URL;
        await axios.post(dataUrl, sendEmailData);
        dispatch(petActions.clearResStatus());
        props.history.goBack()
      }

    }
    return () => {
      previousProps.resStatus = resStatus
    }


  }, [resStatus]);

  const onSendEmail = async () => {

    // check end date > startDate
    if (petRequest === "Host") {
      if (!endAfterStart(startDate, endDate)) {
        setMessage("Fill Date Proper");
        setAlert(true);
        setAlertColor("danger");
        return;
      }
    }

    // call host mutation
    if (petRequest === "Host") {
      let obj = {
        email: email,
        first_name: name,
        last_name: "",
        pet_id: pet?.id,
        expiry: moment(endDate).format("YYYY-MM-DD"),
        host: true,
        start_date: moment(startDate).format("YYYY-MM-DD")
      }
      dispatch(petActions.petParentRequest(obj));

    }

    // call parent mutation
    if (petRequest === "Parent") {
      let obj = {
        email: email,
        first_name: name,
        last_name: "",
        pet_id: pet?.id,
        co_parent: true,
      }
      dispatch(petActions.petParentRequest(obj));

    }

    // if (resStatus) {
    //   let sendEmailData = {
    //     // recipients: emails.length > 0 && emails,
    //     subject: petRequest === "Host" ? `Someone has requested to become a HOST of ${pet?.name}` : `Someone has requested to become a PARENT of ${pet?.name}`,
    //     body: petRequest === "Host" ? requestHostEmailTemplate() : requestParentEmailTemplate()
    //   };
    //   let dataUrl = process.env.REACT_APP_EMAIL_BASE_URL;
    //   await axios.post(dataUrl, sendEmailData);
    // }

    // dispatch(petActions.clearResStatus());

    // setAlert(true);
    // setMessage("Email sent");
    // setAlertColor("success");
    // await props.history.goBack()



  };


  function datePart(date) {
    return moment(date).utc().local().format('M/DD/YYYY');
  }

  return (
    <React.Fragment>
      <div className="alert-list">
        {alert &&
          <Alert color={alertColor}>{message}</Alert>
        }
      </div>
      {
        loading ?
          <h2 className="text-center text-muted mt-5">Loading...</h2> :
          <React.Fragment>
            {
              pet && Object.keys(pet).length > 0 &&
              <React.Fragment>
                <section style={{ height: `${height}px`, width: `${width}px`, position: "relative" }}>
                  <Container fluid className="pt-3">
                    <Row>
                      <Col>
                        <div className="header_prp">
                          <img src={BackIcon} className="backIcon_ppr" alt="" onClick={e => props.history.goBack()} />
                          <img src={petFilename ? petImageUrl : NoPetImage} className="pet_img" alt="" />
                          <h3 className="text_wrapping">{pet?.name}</h3>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <Container fluid className="mt-3">
                    <Row>
                      <Col>
                        <div>
                          <ListGroup>
                            <div className="">
                              <ListGroupItem className="pl-2 pr-2">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                  <span className="left_text">Type of request</span>
                                  <span>
                                    <Input type="select" className="right_text" style={{ border: "none", outline: "none" }} onChange={(e) => {
                                      setPetRequest(e.target.value);
                                    }}>
                                      <option value="Parent">To be a Parent</option>
                                      <option value="Host">To be a Host</option>
                                    </Input>
                                  </span>
                                </div>
                              </ListGroupItem>
                              <ListGroupItem className="pl-2 pr-2">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                  <span className="left_text">Your Email</span>
                                  {/* <span className="right_text">john.doe@gmail.com</span> */}
                                  <input type="email" name="email" onChange={e => setEmail(e.target.value)} className="right_text text-right" />
                                </div>
                              </ListGroupItem>
                              <ListGroupItem className="pl-2 pr-2">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                  <span className="left_text">Your Name</span>
                                  {/* <span className="right_text">John Doe</span> */}
                                  <input type="text" name="name" onChange={e => setName(e.target.value)} className="right_text text-right" />
                                </div>
                              </ListGroupItem>
                              {
                                petRequest === "Host" &&
                                <React.Fragment>
                                  <ListGroupItem className="pl-2 pr-2">
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                      <span className="left_text">Start date</span>
                                      {/* <span className="right_text">10/01/2022</span> */}
                                      <Flatpickr
                                        options={{
                                          monthSelectorType: "dropdown",
                                          disableMobile: true,
                                          dateFormat: "m/d/Y",
                                          minDate: (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear()
                                        }}
                                        value={datePart(startDate)}
                                        onChange={([date]) => {
                                          const d = new Date(date)
                                          setStartDate(d);
                                        }}
                                        className="right_text text-right"
                                      />

                                      {/* <input 
                                        type="date" 
                                        min={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                        name="startDate"
                                        onChange={e => setStartDate(e.target.value)}
                                        className="right_text text-right"
                                        /> */}
                                    </div>
                                  </ListGroupItem>
                                  <ListGroupItem className="pl-2 pr-2">
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                      <span className="left_text">End date</span>
                                      {/* <span className="right_text">10/13/2023</span> */}
                                      {/* <input 
                                        type="date"
                                        min={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]
                                        name="endDate"
                                        onChange={e => setEndDate(e.target.value)}
                                        className="right_text text-right"
                                        /> */}
                                      <Flatpickr
                                        options={{
                                          monthSelectorType: "dropdown",
                                          disableMobile: true,
                                          dateFormat: "m/d/Y",
                                          minDate: (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear(),
                                          minDate: (new Date().getMonth() + 1) + '/' + new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getDate() + '/' + new Date().getFullYear()
                                          // enable: [
                                          //   {
                                          //     from: "12/25/2000",
                                          //     to: (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear(),
                                          //   }
                                          // ]
                                        }}
                                        value={datePart(endDate)}
                                        onChange={([date]) => {
                                          const d = new Date(date)
                                          setEndDate(d);
                                        }}
                                        className="right_text text-right"
                                      />

                                    </div>
                                  </ListGroupItem>
                                </React.Fragment>
                              }
                            </div>
                          </ListGroup>
                        </div>
                        <div className="p-2" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "60px" }}>
                          {
                            petRequest === "Host" ?
                              <span className="para">Hosts have limited control over pet</span> :
                              <span className="para">Parents have full control over pet</span>
                          }
                          <a href="https://jelliepets.com/help" target="_blank"><img src={exlaimation} className="exclIcon_ppr" alt="" /></a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  {
                    pet && Object.keys(pet).length > 0 &&
                    <React.Fragment>
                      {
                        pet && valid &&
                        <React.Fragment>
                          <Container fluid style={{ position: 'absolute', bottom: "86px" }}>
                            <Row>
                              <Col style={{ padding: "0px" }}>
                                <div className="box_request" onClick={onSendEmail}>
                                  <Row className="box_row" style={{ width: `${width - 30}px` }}>
                                    {/* <Col md="3" sm="3" xs="3" className="text-center"> */}
                                    {/* <svg className="be_host_icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20" cy="20" r="20" fill="#F4FCFD" />
                                        <path d="M20.3175 21.3608C22.3681 21.3608 24.0305 19.7459 24.0305 17.7539C24.0305 15.7618 22.3681 14.147 20.3175 14.147C18.2669 14.147 16.6045 15.7618 16.6045 17.7539C16.6045 19.7459 18.2669 21.3608 20.3175 21.3608Z" fill="#24CCE0" />
                                        <path d="M20.2941 10C14.6246 10 10 14.4708 10 20C10 25.5292 14.6246 30 20.2941 30C25.9637 30 30.5882 25.5076 30.5882 20C30.5882 14.4924 25.9637 10 20.2941 10ZM26.3416 26.3067C25.5857 24.3413 23.9404 22.2462 20.2941 22.2462C16.6701 22.2462 15.0025 24.3413 14.2466 26.3067C12.5124 24.73 11.4007 22.4838 11.4007 20C11.4007 15.2484 15.3805 11.3607 20.2941 11.3607C25.2077 11.3607 29.1875 15.2268 29.1875 20C29.1875 22.4838 28.0759 24.73 26.3416 26.3067Z" fill="#24CCE0" />
                                        <path d="M33.9997 9.59961C33.7911 9.59961 33.622 9.76875 33.622 9.97739V12.6218H30.9775C30.7689 12.6218 30.5997 12.791 30.5997 12.9996C30.5997 13.2083 30.7689 13.3774 30.9775 13.3774H33.622V16.0218C33.622 16.2305 33.7911 16.3996 33.9997 16.3996C34.2084 16.3996 34.3775 16.2305 34.3775 16.0218V13.3774H37.022C37.2306 13.3774 37.3997 13.2083 37.3997 12.9996C37.3997 12.791 37.2306 12.6218 37.022 12.6218H34.3775V9.97739C34.3775 9.76875 34.2084 9.59961 33.9997 9.59961Z" fill="#24CCE0" stroke="#24CCE0" stroke-width="0.4" />
                                      </svg> */}
                                    {/* </Col> */}
                                    <Col md="12" sm="12" xs="12" className="text-center"><span className="box_text_prp">Request association</span></Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </section>
              </React.Fragment>
            }
          </React.Fragment>
      }
    </React.Fragment>
  )
};

export default PetRequestParent;