import React, { createRef, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Modal, ModalBody, Row } from "reactstrap";
import "./petDigitalId.css";
import BackIcon from "../../assets/icons/Icon_256w_Back_Black.png";
import exlaimation from "../../assets/icons/Button_256w_Information.png";
import Share from "../../assets/icons/Button_256w_Share.png";
import QRCodeStyling from "qr-code-styling";
import LOGO from "../../assets/untitled folder/logo.png";
import * as petActions from "../../redux/pet/petActions";
import * as petReducers from "../../redux/pet/petReducers";
import { useDispatch, useSelector } from 'react-redux';
import * as imageActions from '../../redux/image/imageActions';
import * as imageReducers from '../../redux/image/imageReducers';
import NoPetImage from '../../assets/icons/Icon_256w_UnSelectedPet_NoImage.png';
import CryptoJS from "crypto-js";
import { triggerBase64Download } from 'react-base64-downloader';
import useWindowDimensions from "../../helper";

let PetDigitalId = (props) => {

  const [petProfile, setPetProfile] = useState({});
  const [qrUrl, setQrUrl] = useState();
  const [petFilename, setPetFilename] = useState("");
  const [petId, setPetId] = useState();
  const { width } = useWindowDimensions();
  // const petId = useParams().petId;
  // const qrRef = useRef(null);
  const dispatch = useDispatch();

  // get pet details
  useEffect(() => {
    const decryptpetId = CryptoJS.AES.decrypt(sessionStorage.getItem('petId'), process.env.REACT_APP_CRYPTO_SECRET)
    setPetId(Number(JSON.parse(decryptpetId.toString(CryptoJS.enc.Utf8))));
    petId && dispatch(petActions.getPetDetails(petId));
  }, [petId]);

  // get pet info
  let petInfo = useSelector((state) => {
    return state[petReducers.petFeatureKey];
  });

  let { loading, pet, error } = petInfo;
  if (error) console.error(error);

  // get pet image
  useEffect(() => {
    if (pet?.pet_images?.length > 0) {
      const fileName = pet?.pet_images?.filter(data => data.type === "PROFILE");
      const exactFileName = fileName[fileName.length - 1]?.filename;
      setPetFilename(exactFileName);
      exactFileName && dispatch(imageActions.getPetImage(exactFileName));
    }
  }, [pet]);

  let imageInfo = useSelector((state) => {
    return state[imageReducers.imageFeatureKey];
  });

  let { petImageUrl } = imageInfo;

  // useEffect(() => {
  //   if (petId) {
  //     setQrUrl(`https://profile.jellie.io/${petId}`)
  //   }
  // }, [petId]);

  // qrcode
  // const qrCode = new QRCodeStyling({
  //   type: "png",
  //   dotsOptions: {
  //     color: "#000",
  //     type: "rounded"
  //   },
  //   backgroundOptions: {
  //     color: "#fff"
  //   },
  //   imageOptions: {
  //     crossOrigin: "anonymous",
  //   },
  //   cornersSquareOptions: {
  //     type: "extra-rounded"
  //   },
  //   cornersDotOptions: {
  //     color: "#f5813c",
  //     type: "dot"
  //   }
  // });

  // useEffect(() => {
  //   qrCode.append(qrRef.current);
  //   qrCode.update({
  //     data: qrUrl,
  //     image: LOGO
  //   });
  // }, [petId, qrUrl]);

  // const downloadQR = () => {
  //   if (qrUrl) {
  //     qrCode.update({
  //       data: qrUrl,
  //       image: LOGO
  //     });
  //     qrCode.download({
  //       extension: "png"
  //     });
  //   }
  // };

  return (
    <React.Fragment>
      <React.Fragment>
        <React.Fragment>
          <section>
            <Container fluid className="mt-3">
              <Row>
                <Col lg="6" md="6" sm="6" xs="6">
                  <div className="header_pdi">
                    <img src={BackIcon} className="backIcon_pdi" alt="" onClick={e => props.history.goBack()} />
                    <img src={petFilename ? petImageUrl : NoPetImage} className="pet_img" alt="" />
                    <h3 className="text_wrapping">{pet?.name ? pet.name : ""}</h3>
                  </div>
                </Col>
                <Col lg="6" md="6" sm="6" xs="6">
                  <div className="float-right px-2 share_icon_pdi">
                    {
                      pet?.qr_base64 !== null &&
                      <img src={Share} onClick={(e) => {
                        triggerBase64Download(`data:image/png;base64,${pet?.qr_base64}`, 'qr_code')
                        // downloadQR()
                        // getImage();
                      }} alt="" />
                    }
                  </div>
                </Col>
              </Row>
            </Container>
            <React.Fragment>
              <React.Fragment>
                <Container fluid className="mt-3">
                  <Row>
                    <Col>
                      <div className="text-center" style={{ border: "0.5px solid #E5E5E5", borderRadius: "10px" }}>
                        {
                          pet?.qr_base64 !== null ?
                            <img src={`data:image/png;base64,${pet?.qr_base64}`} style={{ width: `${width >= 576 ? '300px' : '100%'}`, height: "inherit", border: "0.5px solid #E5E5E5", borderRadius: "10px" }} alt="" /> :
                            <div style={{ width: "100%", height: "400px", border: "0.5px solid #E5E5E5", borderRadius: "10px" }}></div>
                        }
                        {/* <div ref={qrRef} className="p-2" ></div> */}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </React.Fragment>
            </React.Fragment>
            <Container fluid>
              <Row>
                <Col>
                  <div className="p-2" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "60px" }}>
                    <span className="para">QR linked to this pet's public profile</span>
                    <a href="https://jelliepets.com/help" target="_blank"><img src={exlaimation} className="exclIcon_pdi" alt="" /></a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </React.Fragment>
      </React.Fragment>
    </React.Fragment>
  )
};

export default PetDigitalId;